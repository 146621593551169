/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable dot-notation */
//eslint-disable @typescript-eslint/no-floating-promises
/* eslint require-atomic-updates: error */

import * as React from 'react';
import { Button, Combobox, Option, DrawerBody, DrawerHeader, DrawerHeaderTitle, FluentProvider, Input, Label, OverlayDrawer, SelectTabData, SelectTabEvent, Spinner, Tab, TabList, TabValue, Divider, Menu, MenuTrigger, MenuButton, MenuPopover, MenuList, MenuItem, InlineDrawer, Checkbox, Dialog, DialogSurface, DialogBody, DialogTitle, DialogContent, DialogActions, DialogTrigger, DataGrid, createTableColumn, TableColumnDefinition, DataGridBody, DataGridRow, DataGridCell, DrawerFooter, makeStyles, shorthands, Textarea, Toast, ToastTitle, Toaster, useToastController, useId, ComboboxProps, OptionGroup, Persona, Tooltip, MessageBar, MessageBarBody, Link } from '@fluentui/react-components';
import RTCContext from '../RTCWrapper/RTCContext';
import { useEffect, useState, useContext } from 'react';
import { StackShim } from '@fluentui/react-migration-v8-v9';
import { TaskListLtr20Regular, Add20Regular, AddSquareMultiple20Regular, ArrowDown24Regular, ArrowReset24Regular, ArrowUp24Regular, ChevronDown20Regular, ChevronUp20Regular, Comment20Regular, Dismiss24Regular, Edit20Regular, Link20Regular, List20Regular, NumberSymbolSquare20Regular, PanelLeftExpand20Regular, PanelRightExpand20Regular, Search20Regular, Send20Regular, SubtractSquareMultiple20Regular, Wand20Regular, ClipboardTaskAdd20Regular, PeopleTeam20Regular, Channel20Regular, Tag20Regular, Delete20Regular, Person20Regular, PersonSupport20Regular } from '@fluentui/react-icons';
import moment from 'moment';
import Pagination from "react-js-pagination";
import { Icon } from '@fluentui/react/lib/Icon';
import { DatePicker } from '@fluentui/react/lib/DatePicker';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { MessageCommentItem, MessageItem, MessageRoleAction, MissingMessageItem, TenantMessageItem } from '../../common/interfaces/IMessageItem';
import { ViewItem, ViewRefiners, ViewRoleAssignment } from '../../common/interfaces/IViewItem';
import CommentsService from '../../common/services/CommentsService/CommentsService';
import ViewsService from '../../common/services/ViewsService/ViewService';
import MessageService from '../../common/services/MessageService/MessageService';
import TeamsService from '../../common/services/TeamsService/TeamsService';
import { app } from '@microsoft/teams-js';
import { TeamsChannel } from '../../common/interfaces/ITeamsChannel';
import PlannerService from '../../common/services/PlannerService/PlannerService';
import ToDoService from '../../common/services/ToDoService/ToDoService';
import { ExportDestination, IExportTaskDestination } from '../../common/interfaces/IExportTaskDestination';
import PeoplePicker from '../peoplePickerControl/PeoplePicker';
import { IGroupOrPerson } from '../peoplePickerControl/SPPeopleSearchService';
import { WSInfoDetail, WSInfoService } from '../../common/services/WSInfoService/WSInfoService';
import { IMentionOption, MentionType } from '../../common/interfaces/IMentionOption';
import { sanitize } from 'dompurify';
import { LicenseType } from '../../common/interfaces/ILicenseStatus';
import { MessageCard } from '../MessageCard/MessageCard';
import { ReactHelper } from '../../common/helpers/ReactHelper';
import { sha256 } from 'js-sha256';
import { GetGraphAccessToken } from '../../common/services/HttpClientService/HttpClientService';
import HttpHeaderHelper from '../../common/helpers/HttpHeadersHelper';
import axios from 'axios';
import { AppConstants } from '../../common/Constants/AppConstants';
import IRtCDashboardProps from '../../common/interfaces/IRtCDashboardProps';



const messageStyles = makeStyles({
    badgeLabelStyle: {
        ...shorthands.padding('12px, 10px, 12px, 10px'), 
        //color: '#BDBDBD', 
        display: 'block', 
        textAlign: 'center', 
        width: '75px', 
        marginBottom: '5px'
    },
    mainMessageBody: {             
        display: 'inline-flex',
        width: '98.5%',
        ...shorthands.padding('16px'),
        boxShadow: '0px 0.6px 1.8px rgba(0, 0, 0, 0.25)',
        ...shorthands.borderTop('1px solid #F8F8F8'),
        ...shorthands.borderRight('1px solid #F8F8F8'),
        ...shorthands.borderBottom('1px solid #F8F8F8')        
    },
    drawerTitle: {
        '> h2': {
            width: '85% !important'
        }      
    },
    whiteGlove: {
        ':hover': {
            cursor: 'pointer'
        }
    }
});

export const RtCDashboard: React.FunctionComponent<IRtCDashboardProps> = (props) => {
    const rtcContext = useContext(RTCContext);

    const [searchQuery, setSearchQuery] = useState<string>('');
    const [searchQueryMissingTickets, setSearchQueryMissingTickets] = useState<string>('');

    const [loadedServices, setLoadedServices] = useState<string[]>([]);
    const [messages, setMessages] = useState<MessageItem[]>([]);
    const [filteredMessages, setFilteredMessages] = useState<MessageItem[]>([]);
    const [viewItems, setViewItems] = useState<ViewItem[]>([]);
    const [viewRefiners, setViewRefiners] = useState<ViewRefiners[]>([]);
    const [editableViewRefiners, setEditableViewRefiners] = useState<ViewRefiners[]>([]);
    const [viewServices, setViewServices] = useState<string[]>([]);
    const [missingMessages, setMissingMessages] = useState<MissingMessageItem[]>([]);
    const [tenantMessageItems, setTenantMessageItems] = useState<TenantMessageItem[]>([]);

    const [pageNumber, setPageNumber] = useState<number>(1);

    const [firstLoad, setFirstLoad] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [tabSelected, setTabSelected] = useState<TabValue>('inbox');
    const [openMessagePanel, setOpenMessagePanel] = useState<boolean>(false);
    const [openViewRefiners, setOpenViewRefiners] = useState<boolean>(true);
    const [openMissingTicketsDialog, setOpenMissingTicketsDialog] = useState<boolean>(false);
    const [openCreateTaskDialog, setOpenCreateTaskDialog] = useState<boolean>(false);
    const [openViewPanel, setOpenViewPanel] = useState<boolean>(false);
    const [openShareMessageDialog, setOpenShareMessageDialog] = useState<boolean>(false);
    const [selectedMessage, setSelectedMessage] = useState<MessageItem>(undefined);
    const [selectedView, setSelectedView] = useState<ViewItem>(undefined);
    const [messagesExpanded, setMessagesExpanded] = useState<boolean>(false);
    const [viewEditMode, setViewEditMode] = useState<boolean>(false);
    const [editableView, setEditableView] = useState<ViewItem>(undefined);    
    const [selectedMessageIndex, setSelectedMessageIndex] = useState<number>(-1);

    const [openArchiveDialog, setOpenArchiveDialog] = useState<boolean>(false);

    const [newComment, setNewComment] = useState<string>('');

    const [previousStatus, setPreviousStatus] = useState<string>(undefined);

    const [renderITAdminActionsRefiner, setRenderITAdminActionsRefiner] = useState<boolean>(false);
    const [renderAppOwnerActionsRefiner, setRenderAppOwnerActionsRefiner] = useState<boolean>(false);
    const [renderSecurityActionsRefiner, setRenderSecurityActionsRefiner] = useState<boolean>(false);
    const [renderComplianceActionsRefiner, setRenderComplianceActionsRefiner] = useState<boolean>(false);

    const [channels, setChannels] = useState<TeamsChannel[]>([]);
    const [selectedChannel, setSelectedChannel] = useState<TeamsChannel>(undefined);
    const [cardDescription, setCardDescription] = useState<string>('');
	const [mentionsValue, setMentionsValue] = useState<string>('');
    const [mentions, setMentions] = useState<Array<IMentionOption>>([]);
    const [mentionOptions, setMentionOptions] = useState<Array<IMentionOption>>([]);
    const [matchingOptions, setMatchingOptions] = useState<Array<IMentionOption>>([]);

    const [taskDestinationOptions, setTaskDestinationOptions] = useState<IExportTaskDestination[]>([]);
    const [taskDestination, setTaskDestination] = useState<IExportTaskDestination>(undefined);
    const [taskDueDate, setTaskDueDate] = useState<Date>(undefined);
    const [taskNotes, setTaskNotes] = useState<string>('');
    const [taskUsers, setTaskUsers] = useState<IGroupOrPerson[]>(undefined);

    const [savingTask, setSavingTask] = useState<boolean>(false);
    const [savingCard, setSavingCard] = useState<boolean>(false);

    const [partitionKey, setPartitionKey] = useState<string>('');

    const [cutoffMonths, setCutoffMonths] = useState<number>(3);

    const [siteLogo, setSiteLogo] = useState<string>('');
	const [siteColor, setSiteColor] = useState<string>('');

    const styles = messageStyles();

    const toasterId = useId("breadToaster");
    const { dispatchToast } = useToastController(toasterId);

    const paginationClassName = mergeStyleSets({
        paginationColor:{
            selectors:{
                "ul li a":{
                color: rtcContext.themeV9.colorBrandForegroundLink
                }
            }
        },
        pagination:{
          textAlign: 'center',
          selectors: {
            'ul':{
              display: 'inline-block',
              paddingLeft: 0,
              margin: '20px 0',
              borderRadius: 4,
              selectors: {
                'li': {
                  display: 'inline',
                  selectors: {
                    'a': {
                      float: 'left',
                      padding: '4px 8px',
                      textDecoration: 'none',
                      borderRadius: 15,
                      selectors: {
                        'i': {
                          fontSize: 11
                        }
                      }
                    },
                    'a:visited':{
                      color: 'inherit'
                    }
                  }
                },
                'li.active': {
                    fontWeight: 800
                },
                ':global':{
                  selectors:{
                    'li.disabled': {
                      selectors:{
                        'a':{
                          color: rtcContext.themeV9.colorBrandForegroundLink,
                          selectors: {
                            'i': {
                              color: rtcContext.themeV9.colorBrandForegroundLink
                            }
                          }
                        }
                      }
                    }                    
                  }
                }
              }
            }
          }
        }
      });

    const loadViewRefiners = (editableView: boolean):void => {
        const refiners: ViewRefiners[] = [];

        refiners.push({
            refinerName: 'IT Administrator',
            actions: [{action: MessageRoleAction.ignore, checked: true}, {action: MessageRoleAction.note, checked: true}, {action: MessageRoleAction.consider, checked: true}, {action: MessageRoleAction.act, checked: true}]
        });

        refiners.push({
            refinerName: 'App owner',
            actions: [{action: MessageRoleAction.ignore, checked: true}, {action: MessageRoleAction.note, checked: true}, {action: MessageRoleAction.consider, checked: true}, {action: MessageRoleAction.act, checked: true}]
        });

        refiners.push({
            refinerName: 'Security',
            actions: [{action: MessageRoleAction.ignore, checked: true}, {action: MessageRoleAction.note, checked: true}, {action: MessageRoleAction.consider, checked: true}, {action: MessageRoleAction.act, checked: true}]
        });

        refiners.push({
            refinerName: 'Compliance',
            actions: [{action: MessageRoleAction.ignore, checked: true}, {action: MessageRoleAction.note, checked: true}, {action: MessageRoleAction.consider, checked: true}, {action: MessageRoleAction.act, checked: true}]
        });

        if(editableView){
            setEditableViewRefiners(refiners);
        }else{
            refiners.forEach((refiner) => {refiner.actions.forEach((action) => {action.checked = false})});

            setViewRefiners(refiners);
        }        
    };

    const loadViews = async():Promise<void> => {
        const views: ViewItem[] = [];

        views.push({
            viewId: -1,
            viewName: rtcContext.localization.WebParts.Dashboard.allItemsViewOption,
            itAdministratorAssignments: [{ action: MessageRoleAction.ignore, checked: false }, { action: MessageRoleAction.note, checked: false }, { action: MessageRoleAction.consider, checked: false }, { action: MessageRoleAction.act, checked: false }],
            appOwnerAssignments: [{ action: MessageRoleAction.ignore, checked: false }, { action: MessageRoleAction.note, checked: false }, { action: MessageRoleAction.consider, checked: false }, { action: MessageRoleAction.act, checked: false }],
            securityAssignments: [{ action: MessageRoleAction.ignore, checked: false }, { action: MessageRoleAction.note, checked: false }, { action: MessageRoleAction.consider, checked: false }, { action: MessageRoleAction.act, checked: false }],
            complianceAssignments: [{ action: MessageRoleAction.ignore, checked: false }, { action: MessageRoleAction.note, checked: false }, { action: MessageRoleAction.consider, checked: false }, { action: MessageRoleAction.act, checked: false }],
            services: []
            //services: loadedServices
        });

        try{
            const viewsFromShp: ViewItem[] = await ViewsService.getViewsForUser(rtcContext.siteUrl, rtcContext.sitePath, rtcContext.userDisplayName, rtcContext.siteId);
        
            if(viewsFromShp && viewsFromShp.length > 0){
                views.push(...viewsFromShp);
            }
        }catch(error){
            console.error('Couldn\'t load the RtC views');
            console.error(error);
        }        

        setViewItems(views);
    };

    const updateTenantItemState = (rowKey: string, read: boolean, status: string): void => {
        const clonedTenantItems = JSON.parse(JSON.stringify(tenantMessageItems));

        const messageItem = clonedTenantItems.find(item => item.messageId === rowKey);

        if(messageItem){
            if(read){
                messageItem.isRead = true;                
            }

            messageItem.messageStatus = status;
        }else{
            const newMessageItem: TenantMessageItem = {
                messageStatus: status,
                isRead: read,
                messageId: rowKey,
                messageDate: '',
                statusDate: ''
            }

            clonedTenantItems.push(newMessageItem);
        }

        setTenantMessageItems(clonedTenantItems);
    };

    const searchMissingMessages = async(): Promise<void> => {
        const ids: string[] = searchQueryMissingTickets.split(';')

        let loadedMessages: MissingMessageItem[] = [];
        
        if(props.license.Type === LicenseType.FreeTrial){
            loadedMessages = await MessageService.retrieveMissingMessagesFreeTrial(ids, rtcContext.localization);
        }else{
            loadedMessages = await MessageService.retrieveMissingMessages(props.subscriptionKey, ids, rtcContext.localization);
        }     

        setMissingMessages(loadedMessages);
    };

    const keyPressSearchMissing = (e):void => {
        //it triggers by pressing the enter key
        if (e.charCode === 13) {
            searchMissingMessages(); // eslint-disable-line @typescript-eslint/no-use-before-define
        }
    };

    const setPartitionKeyValue = (): string => {
        let partitionKeyValue = `${rtcContext.siteId}_${rtcContext.upn}`;
        
        if(props.sharedMode){
            partitionKeyValue = rtcContext.siteId;
        }

        partitionKeyValue = sha256(partitionKeyValue);

        setPartitionKey(partitionKeyValue);

        return partitionKeyValue;
    };

    const loadMessages = async(query: string, itAdminLevels: string[], appOwnerLevels: string[], complianceLevels: string[], securityLevels: string[], services: string[], refreshTenantData: boolean): Promise<MessageItem[]> => {
        let loadedMessages: MessageItem[] = [];

        if(props.license.Type === LicenseType.FreeTrial){
            loadedMessages = await MessageService.retrieveMessagesFreeTrial(query, itAdminLevels, appOwnerLevels, complianceLevels, securityLevels, services);
        }else{
            loadedMessages = await MessageService.retrieveMessages(query, props.license.Type !== LicenseType.Compliance, itAdminLevels, appOwnerLevels, complianceLevels, securityLevels, services, cutoffMonths, props.subscriptionKey);
        }

        const partitionKeyValue = setPartitionKeyValue();

        let tenantMessages: TenantMessageItem[] = [];
        
        if(refreshTenantData && props.license.Type !== LicenseType.FreeTrial){
            tenantMessages = await MessageService.retrieveTenantMessageItems(partitionKeyValue, props.subscriptionKey);

            setTenantMessageItems(tenantMessages);
        }else{
            tenantMessages = tenantMessageItems;
        }

        if(loadedMessages && loadedMessages.length > 0){
            if(tenantMessages && tenantMessages.length > 0){
                if(loadedMessages.length < tenantMessages.length){
                    for (let i = 0; i < loadedMessages.length; i++){
                        const loadedMessage = loadedMessages[i];
        
                        const messageSpecific = tenantMessages.find((messageObj) => messageObj.messageId === loadedMessage.messageId);

                        const category = loadedMessage.messageCategory?.toLowerCase();

                        if(messageSpecific && (category !== 'content update' || (category === 'content update' && moment(messageSpecific.timeStamp).isAfter(moment(loadedMessage.messageDatePublishedRaw))))){
                            loadedMessage.isRead = messageSpecific.isRead;
                            loadedMessage.messageStatus = messageSpecific.messageStatus;
                        }
                    }
                }else{
                    for (let i = 0; i < tenantMessages.length; i++){
                        const tenantMessage = tenantMessages[i];
        
                        const messageSpecific = loadedMessages.find((messageObj) => messageObj.messageId === tenantMessage.messageId);

                        const category = messageSpecific?.messageCategory?.toLowerCase();

                        if(messageSpecific && (category !== 'content update' || (category === 'content update' && moment(tenantMessage.timeStamp).isAfter(moment(messageSpecific.messageDatePublishedRaw))))){
                            messageSpecific.isRead = tenantMessage.isRead;
                            messageSpecific.messageStatus = tenantMessage.messageStatus;
                        }
                    }
                }                
            }            

            setMessages(loadedMessages);
        }        

        return loadedMessages;
    };

    const loadServices = async(): Promise<void> => {
        let services: string[] = [];

        if(props.license.Type === LicenseType.FreeTrial){
            services = await MessageService.retrieveServiceFacetsFreeTrial();
        }else{
            services = await MessageService.retrieveServiceFacets(props.subscriptionKey);
        }

        setViewServices([]);

        setLoadedServices(services);
    };
    
    const loadSiteData = async(): Promise<void> => {
		try{
			const wsinfoservice=new WSInfoService();
			const wsInfo=await wsinfoservice.getWSInfo(rtcContext.siteUrl, WSInfoDetail.WebInfo | WSInfoDetail.GroupInfo,(realInfo)=>{
				setSiteColor(realInfo.WebInfo.Color);
				setSiteLogo(realInfo.WebInfo.SiteLogoUrl);
			});

			setSiteColor(wsInfo.WebInfo.Color);
			setSiteLogo(wsInfo.WebInfo.SiteLogoUrl);
		} catch(error){
			console.error(`Couldn't retrieve site information for workspace ${rtcContext.siteUrl}`);
            console.error(error);
		}
	};

    const onBlurMention = (): void => {
        if(mentions && mentions.length > 0){
            const labels = mentions.map(({ label }) => label);
 
            setMentionsValue(`@${labels.join(", @")}`);
        }        
    };
 
    const onChangeMention = (event): void => {
        const value = event.target.value.trim();

        const matches = mentionOptions.filter(
          (option) => option.label.toLowerCase().indexOf(value.toLowerCase()) === 0
        );
 
        setMentionsValue(value);
 
        setMatchingOptions(matches);
    };
   
    const onMentionOptionSelect = (event, data): void => {
        const newMentions: IMentionOption[] = [];
 
        data.selectedOptions.forEach((option) => {
            const selectedMention = mentionOptions.find((mentionOp) => { return mentionOp.key === option; });
 
            if(selectedMention){
                newMentions.push(selectedMention);
            }
        });
 
        setMentions(newMentions);    
    };

    const loadMentions = async(team: app.TeamInfo, channelId: string, channelName: string): Promise<void> => {
        try{
            const mentions: IMentionOption[] = [];
 
            mentions.push({ key: team.groupId, label: team.displayName, type: MentionType.Team});
 
            mentions.push({ key: channelId, label: channelName, type: MentionType.Channel});
 
            const members = await TeamsService.getChannelMembers(team.groupId, channelId);

            if(members && members.length > 0){
                members.forEach((member) => {
                    mentions.push({ key: member.id, label: member.name, type: MentionType.Member});
                });
            }
 
            try{
                const accessToken = await GetGraphAccessToken();

                const reqHeaders = HttpHeaderHelper.getHeaders(undefined, false, false, accessToken);

                const tagsResult = await axios.get(`https://graph.microsoft.com/beta/teams/${team.groupId}/tags`, { headers: reqHeaders });
 
                if(tagsResult && tagsResult.data.value && tagsResult.data.value.length > 0){
                    tagsResult.data.value.forEach((tag) => {
                        mentions.push({ key: tag.id, label: tag.displayName, type: MentionType.Tag});
                    });
                }
            }catch(tagsError){
                console.error(tagsError);
            }            
 
            setMentionOptions(mentions);
            setMatchingOptions([...mentions])
        }catch(error){
            console.error(error);
        }
    };

    const onTabSelect = async(event: SelectTabEvent, data: SelectTabData): Promise<void> => {
        setTabSelected(data.value);

        setPageNumber(1);
    };

    const resetCustomRefiners = (): void => {
        const restoredRefiners: ViewRefiners[] = [];

        restoredRefiners.push({refinerName: 'IT Administrator', actions:selectedView.itAdministratorAssignments});
        restoredRefiners.push({refinerName: 'App owner', actions:selectedView.appOwnerAssignments});
        restoredRefiners.push({refinerName: 'Security', actions:selectedView.securityAssignments});
        restoredRefiners.push({refinerName: 'Compliance', actions:selectedView.complianceAssignments});

        setViewRefiners(restoredRefiners);

        setViewServices(selectedView.services);
    };

    const openMessage = async(messageItem: number | MessageItem): Promise<void> => {
        let message: MessageItem = undefined;

        let messageIndex: number = -1;

        if(typeof messageItem === 'number'){
            message = filteredMessages[messageItem];

            if(!message){
                message = messages[messageItem];
            }

            messageIndex = messageItem;
        }else{
            message = messageItem as MessageItem;

            messageIndex = filteredMessages.findIndex(messageObj => message.messageId === messageObj.messageId);
        }

        if(message){   
            if(!message.isRead){
                message.isRead = !message.isRead;

                if(props.license.Type !== LicenseType.FreeTrial){
                    await MessageService.upsertMessageItem(partitionKey, message.messageId, message.messageStatus, moment().toDate(), message.isRead, moment(message.messageDateCreated, 'DD.MM.YYYY').toDate(), props.subscriptionKey);
                }
                
                updateTenantItemState(message.messageId, message.isRead, message.messageStatus);
            }

            const comments = await CommentsService.getCommentsForMessage(message.messageId, rtcContext.siteUrl, rtcContext.sitePath, rtcContext.siteId);

            //eslint-disable-next-line require-atomic-updates
            message.messageComments = comments;

            setNewComment('');

            setPreviousStatus(message.messageStatus);

            setSelectedMessage(message);
            setSelectedMessageIndex(messageIndex);

            setOpenMessagePanel(true);
        }
    };

    const search = async(refreshTenantData: boolean): Promise<void> => {
        setIsLoading(true);

        setPageNumber(1);

        let itAdminFilter = [];
        let appOwnerFilter = [];
        let complianceFilter = [];
        let securityFilter = [];

        if(viewRefiners && viewRefiners.length > 0){
            itAdminFilter = viewRefiners.find((refiner) => refiner.refinerName === 'IT Administrator').actions.filter((action) => action.checked).map(action => action.action);
    
            appOwnerFilter = viewRefiners.find((refiner) => refiner.refinerName === 'App owner').actions.filter((action) => action.checked).map(action => action.action);
    
            complianceFilter = viewRefiners.find((refiner) => refiner.refinerName === 'Compliance').actions.filter((action) => action.checked).map(action => action.action);
    
            securityFilter = viewRefiners.find((refiner) => refiner.refinerName === 'Security').actions.filter((action) => action.checked).map(action => action.action);
        }

        //If all the options are selected then we remove the filter
        itAdminFilter = itAdminFilter.length === 4 ? [] : itAdminFilter as string[];
        appOwnerFilter = appOwnerFilter.length === 4 ? [] : appOwnerFilter as string[];
        complianceFilter = complianceFilter.length === 4 ? [] : complianceFilter as string[];
        securityFilter = securityFilter.length === 4 ? [] : securityFilter as string[];
        
        //If all the services are selected then we remove the filter
        const servicesFilter = viewServices.length === loadedServices.length ? [] : viewServices;

        const messagesLoaded = await loadMessages(searchQuery, itAdminFilter, appOwnerFilter, complianceFilter, securityFilter, servicesFilter, refreshTenantData);
        
        if(messagesLoaded && messagesLoaded.length > 0){
            const statusValue = tabSelected.toString().toLocaleLowerCase();

            const preselectedMessages = messagesLoaded.filter((message) => message.messageStatus.toLocaleLowerCase() === statusValue);

            preselectedMessages.forEach((message) => {
                message.cardExpanded = messagesExpanded;
            }); 

            setFilteredMessages(preselectedMessages);
        }else{
            setFilteredMessages([]); 
        }

        setIsLoading(false);
    };    

    useEffect(() => {
        const filteredMessagesClone = JSON.parse(JSON.stringify(filteredMessages));

        filteredMessagesClone.forEach((message) => {
            message.cardExpanded = messagesExpanded;
        });        

        setFilteredMessages(filteredMessagesClone);
    }, [messagesExpanded]);

    useEffect(() => {
        const statusValue = tabSelected.toString().toLocaleLowerCase();
        
        const preselectedMessages = messages.filter((message) => message.messageStatus.toLocaleLowerCase() === statusValue);

        preselectedMessages.forEach((message) => {
            message.cardExpanded = messagesExpanded;
        }); 

        setFilteredMessages(preselectedMessages);
    }, [tabSelected]);

    useEffect(() => {
        if(!firstLoad){
            search(false);
        }        
    }, [viewServices, viewRefiners]);

    useEffect(() => {
        if(!firstLoad){
            search(true);
        }        
    }, [cutoffMonths]);

    useEffect(() => {
        const fp = document.querySelector('.fui-FluentProvider');
        const frame = fp.parentNode;
        (frame as HTMLElement).setAttribute('style', `display: block; overflow: ${openMessagePanel ? 'hidden' : 'auto'}`);
    }, [openMessagePanel]);

    const componentDidMount = async ():Promise<void> =>{
        const partitionKeyValue = setPartitionKeyValue();

        if((props.subscriptionKey && props.subscriptionKey.length > 0) || props.license.Type === LicenseType.FreeTrial){           
            setIsLoading(true);

            //Initial search is with all the filters blank except for the cut off date
            const loadedMessages = await loadMessages('', [], [], [], [], [], true);

            const inboxMessages = loadedMessages.filter(message => message.messageStatus.toLocaleLowerCase() === 'inbox');

            await loadServices();

            await loadSiteData();

            loadViewRefiners(false);         
            
            setFilteredMessages(inboxMessages);

            const messageId: string = rtcContext.teamsContext.page.subPageId;
            
            if(messageId && messageId.length > 0){           
                let message: MessageItem = undefined;

                if(loadedMessages && loadedMessages.length > 0){
                    message = loadedMessages.find((msg) => msg.messageId === messageId);
                }             

                if(message){
                    await openMessage(message);
                }else{
                    const messageItem = await MessageService.retrieveMessage(messageId, props.subscriptionKey);

                    if(messageItem){
                        const tenantMessageItem = await MessageService.retrieveTenantMessageItem(partitionKeyValue, messageId, props.subscriptionKey);

                        if(tenantMessageItem){
                            messageItem.isRead = tenantMessageItem.isRead;
                            messageItem.messageStatus = tenantMessageItem.messageStatus;
                        }

                        await openMessage(messageItem);
                    }else{
                        dispatchToast(
                            <Toast>
                              <ToastTitle>{rtcContext.localization.WebParts.OperationMessages.openMessageError}</ToastTitle>
                            </Toast>,
                            { intent: 'error' }
                        );
                    }
                }    
            }
        }

        setFirstLoad(false);

        setIsLoading(false);
    };

    useEffect(() => {
        //eslint-disable-next-line @typescript-eslint/no-floating-promises
        componentDidMount();
    }, [props.subscriptionKey]);

    useEffect(() => {
        setPartitionKeyValue();
    }, [props.sharedMode]);

    useEffect(() => {
        //eslint-disable-next-line @typescript-eslint/no-floating-promises
        componentDidMount();
    }, []);

    useEffect(() => {
        loadViews();
    }, [loadedServices]);    

    useEffect(() => {
        if(firstLoad && viewItems && viewItems.length > 0){
            setSelectedView(viewItems[0]);
        }
    }, [viewItems]);

    useEffect(() => {
        if(!firstLoad){
            resetCustomRefiners();
        }        
    }, [selectedView]);

    const keyPressSearchMessages = (e):void => {
        //it triggers by pressing the enter key
        if (e.charCode === 13) {
            search(false); // eslint-disable-line @typescript-eslint/no-use-before-define, @typescript-eslint/no-floating-promises
        }
    };

    const viewMode = (editMode: boolean): void => {
        let view: ViewItem = JSON.parse(JSON.stringify(selectedView));

        if(!editMode){
            view = 
            {
                viewId: 0,
                viewName: '',
                itAdministratorAssignments: [{ action: MessageRoleAction.ignore, checked: true }, { action: MessageRoleAction.note, checked: true }, { action: MessageRoleAction.consider, checked: true }, { action: MessageRoleAction.act, checked: true }],
                appOwnerAssignments: [{ action: MessageRoleAction.ignore, checked: true }, { action: MessageRoleAction.note, checked: true }, { action: MessageRoleAction.consider, checked: true }, { action: MessageRoleAction.act, checked: true }],
                securityAssignments: [{ action: MessageRoleAction.ignore, checked: true }, { action: MessageRoleAction.note, checked: true }, { action: MessageRoleAction.consider, checked: true }, { action: MessageRoleAction.act, checked: true }],
                complianceAssignments: [{ action: MessageRoleAction.ignore, checked: true }, { action: MessageRoleAction.note, checked: true }, { action: MessageRoleAction.consider, checked: true }, { action: MessageRoleAction.act, checked: true }],
                services: []
                //services: loadedServices
            };

            loadViewRefiners(true);
        }else{
            const refiners: ViewRefiners[] = [];

            refiners.push({
                refinerName: 'IT Administrator',
                actions: [{action: MessageRoleAction.ignore, checked: view.itAdministratorAssignments.find(role => role.action === MessageRoleAction.ignore).checked}, {action: MessageRoleAction.note, checked: view.itAdministratorAssignments.find(role => role.action === MessageRoleAction.note).checked}, {action: MessageRoleAction.consider, checked: view.itAdministratorAssignments.find(role => role.action === MessageRoleAction.consider).checked}, {action: MessageRoleAction.act, checked: view.itAdministratorAssignments.find(role => role.action === MessageRoleAction.act).checked}]
            });

            refiners.push({
                refinerName: 'App owner',
                actions: [{action: MessageRoleAction.ignore, checked: view.appOwnerAssignments.find(role => role.action === MessageRoleAction.ignore).checked}, {action: MessageRoleAction.note, checked: view.appOwnerAssignments.find(role => role.action === MessageRoleAction.note).checked}, {action: MessageRoleAction.consider, checked: view.appOwnerAssignments.find(role => role.action === MessageRoleAction.consider).checked}, {action: MessageRoleAction.act, checked: view.appOwnerAssignments.find(role => role.action === MessageRoleAction.act).checked}]
            });

            refiners.push({
                refinerName: 'Security',
                actions: [{action: MessageRoleAction.ignore, checked: view.securityAssignments.find(role => role.action === MessageRoleAction.ignore).checked}, {action: MessageRoleAction.note, checked: view.securityAssignments.find(role => role.action === MessageRoleAction.note).checked}, {action: MessageRoleAction.consider, checked: view.securityAssignments.find(role => role.action === MessageRoleAction.consider).checked}, {action: MessageRoleAction.act, checked: view.securityAssignments.find(role => role.action === MessageRoleAction.act).checked}]
            });

            refiners.push({
                refinerName: 'Compliance',
                actions: [{action: MessageRoleAction.ignore, checked: view.complianceAssignments.find(role => role.action === MessageRoleAction.ignore).checked}, {action: MessageRoleAction.note, checked: view.complianceAssignments.find(role => role.action === MessageRoleAction.note).checked}, {action: MessageRoleAction.consider, checked: view.complianceAssignments.find(role => role.action === MessageRoleAction.consider).checked}, {action: MessageRoleAction.act, checked: view.complianceAssignments.find(role => role.action === MessageRoleAction.act).checked}]
            });

            setEditableViewRefiners(refiners);
        }

        setEditableView(view);
        setViewEditMode(editMode);
        setOpenViewPanel(true);
    };

    const providerColumns: TableColumnDefinition<MissingMessageItem>[] = [
        createTableColumn<MissingMessageItem>({
          columnId: "messageId",
          renderCell: (item) => {
            return item.messageId;
          },
        }),
        createTableColumn<MissingMessageItem>({
          columnId: "messageStatus",
          renderCell: (item) => {
            return item.messageStatus;
          },
        })
    ];

    const expandCollapseCard = (index: number, expanded: boolean): void => {
        const filteredMessagesClone = JSON.parse(JSON.stringify(filteredMessages));

        filteredMessagesClone[index].cardExpanded = expanded;

        setFilteredMessages(filteredMessagesClone);
    };

    const onViewNameChange = (newValue: string): void => {
        const editableViewClone: ViewItem = JSON.parse(JSON.stringify(editableView));

        editableViewClone.viewName = newValue;

        setEditableView(editableViewClone);
    };

    const onViewServiceChange = (checked: boolean, serviceId: string): void => {
        const editableViewClone: ViewItem = JSON.parse(JSON.stringify(editableView));

        if(checked){
            editableViewClone.services.push(serviceId);
        }else{
            const posService = editableViewClone.services.findIndex((service) => { return service === serviceId });
            editableViewClone.services.splice(posService, 1);
        }

        setEditableView(editableViewClone);
    };

    const onViewServiceRefinerChange = (checked: boolean, serviceId: string): void => {
        const viewServicesClone: string[] = JSON.parse(JSON.stringify(viewServices));

        if(checked){
            viewServicesClone.push(serviceId);
        }else{
            const posService = viewServicesClone.findIndex((service) => { return service === serviceId });
            viewServicesClone.splice(posService, 1);
        }

        setViewServices(viewServicesClone);
    };

    const onViewRolesChange = (isViewEdit: boolean, refiner: ViewRefiners, action: ViewRoleAssignment, checked: boolean): void => {
        if(isViewEdit){
            const editableViewClone: ViewItem = JSON.parse(JSON.stringify(editableView));

            switch(refiner.refinerName){
                case 'IT Administrator':
                    {
                        const actionToEdit = editableViewClone.itAdministratorAssignments.find((roleAssigment) => { return roleAssigment.action === action.action });
                        actionToEdit.checked = checked;
                    }                        
                    break;
                case 'App owner':
                    {
                        const actionToEdit = editableViewClone.appOwnerAssignments.find((roleAssigment) => { return roleAssigment.action === action.action });
                        actionToEdit.checked = checked;
                    }  
                    break;
                case 'Security':
                    {
                        const actionToEdit = editableViewClone.securityAssignments.find((roleAssigment) => { return roleAssigment.action === action.action });
                        actionToEdit.checked = checked;
                    }  
                    break;
                case 'Compliance':
                    {
                        const actionToEdit = editableViewClone.complianceAssignments.find((roleAssigment) => { return roleAssigment.action === action.action });
                        actionToEdit.checked = checked;
                    }  
                    break;
            }

            setEditableView(editableViewClone);

            const appliedViewClone: ViewRefiners[] = JSON.parse(JSON.stringify(editableViewRefiners));

            const refinerToUpdate: ViewRefiners = appliedViewClone.find((refinerObj) => { return refinerObj.refinerName === refiner.refinerName });

            if(refinerToUpdate){
                const actionToUpdate: ViewRoleAssignment = refinerToUpdate.actions.find((actionObj) => { return actionObj.action === action.action });

                if(actionToUpdate){
                    actionToUpdate.checked = checked;
                }
            }

            setEditableViewRefiners(appliedViewClone);
        }else{
            const appliedViewClone: ViewRefiners[] = JSON.parse(JSON.stringify(viewRefiners));

            const refinerToUpdate: ViewRefiners = appliedViewClone.find((refinerObj) => { return refinerObj.refinerName === refiner.refinerName });

            if(refinerToUpdate){
                const actionToUpdate: ViewRoleAssignment = refinerToUpdate.actions.find((actionObj) => { return actionObj.action === action.action });

                if(actionToUpdate){
                    actionToUpdate.checked = checked;
                }
            }

            setViewRefiners(appliedViewClone);
        }
    };

    const onChannelSelect: ComboboxProps["onOptionSelect"] = (e, data) => {        
        const channel = channels.find((channelObj) => { return channelObj.id === data.optionValue });

        setSelectedChannel(channel);

        loadMentions(rtcContext.teamsContext.team, channel.id, channel.name);
 
        setCardDescription('');
    };

    const onTaskDestinationSelect: ComboboxProps["onOptionSelect"] = (e, data) => {        
        const taskDestination = taskDestinationOptions.find((taskDestinationObj) => { return taskDestinationObj.Id === data.optionValue });

        setTaskDestination(taskDestination);
    };

    const onCutoffDateSelect: ComboboxProps["onOptionSelect"] = (e, data) => {        
        const months = Number.parseInt(data.optionValue);

        if(months !== cutoffMonths){
            setCutoffMonths(months);
        }        
    };

    const onStatusSelect: ComboboxProps["onOptionSelect"] = async(e, data) => {
        let status = 'Archived';        
        
        const messageAux = JSON.parse(JSON.stringify(selectedMessage));

        messageAux.messageStatus = data.optionValue.toLocaleLowerCase() === 'inreview' ? 'Review' : data.optionValue;

        setSelectedMessage(messageAux);

        if(data.optionValue === 'Archived'){          
            setNewComment('');

            setOpenArchiveDialog(true);
        }else{
            status = data.optionValue.toLocaleLowerCase() === 'inreview' ? 'Review' : data.optionValue;

            let result = true;
            
            if(props.license.Type !== LicenseType.FreeTrial){
                result = await MessageService.upsertMessageItem(partitionKey, selectedMessage.messageId, status, moment().toDate(), selectedMessage.isRead, moment(selectedMessage.messageDateCreated, 'DD.MM.YYYY').toDate(), props.subscriptionKey);        
            }

            if(result){
                const message = JSON.parse(JSON.stringify(selectedMessage));
    
                message.messageStatus = status; 
        
                setSelectedMessage(message);

                updateTenantItemState(message.messageId, message.isRead, message.messageStatus);
    
                const messagesClone = JSON.parse(JSON.stringify(messages));
                const messageClone = messagesClone.find(messageObj => messageObj.messageId === selectedMessage.messageId);
    
                if(messageClone){
                    messageClone.messageStatus = status;
                }
    
                setMessages(messagesClone);
    
                const filteredMessagesClone = JSON.parse(JSON.stringify(filteredMessages));
                const filteredMessageClone = filteredMessagesClone.findIndex(messageObj => messageObj.messageId === selectedMessage.messageId);
    
                if(filteredMessageClone > -1){
                    filteredMessagesClone.splice(filteredMessageClone, 1);
                }
    
                setFilteredMessages(filteredMessagesClone);
    
                dispatchToast(
                    <Toast>
                      <ToastTitle>{rtcContext.localization.WebParts.OperationMessages.statusUpdateSuccess}</ToastTitle>
                    </Toast>,
                    { intent: 'success' }
                );
            }else{
                dispatchToast(
                    <Toast>
                      <ToastTitle>{rtcContext.localization.WebParts.OperationMessages.statusUpdateError}</ToastTitle>
                    </Toast>,
                    { intent: 'error' }
                );
            } 
        }               
    };

    const saveView = async(): Promise<void> => {
        const view = JSON.parse(JSON.stringify(editableView));

        view.appOwnerAssignments = editableViewRefiners.find((refiner) => refiner.refinerName === 'App owner').actions;
        view.itAdministratorAssignments = editableViewRefiners.find((refiner) => refiner.refinerName === 'IT Administrator').actions;
        view.securityAssignments = editableViewRefiners.find((refiner) => refiner.refinerName === 'Security').actions;
        view.complianceAssignments = editableViewRefiners.find((refiner) => refiner.refinerName === 'Compliance').actions;

        if(!viewEditMode){
            try{
                await ViewsService.saveView(view, rtcContext.siteUrl, rtcContext.sitePath);                
            }catch(error){  
                console.error(error);
            }
        }else{
            try{
                await ViewsService.updateView(view, rtcContext.siteUrl, rtcContext.sitePath);
            }catch(error){
                console.error(error);
            }
        }

        await loadViews();

        if(viewEditMode){
            setSelectedView(view);
        }

        setOpenViewPanel(false);
    };

    const deleteView = async(): Promise<void> => {
        if(viewEditMode){
            try{
                await ViewsService.deleteView(editableView, rtcContext.siteUrl, rtcContext.sitePath);
            }catch(error){
                console.error(error);
            }
        }

        await loadViews();
        setSelectedView(viewItems[0]);
        setOpenViewPanel(false);
    };

    const saveTask = async(): Promise<void> => {
        try{

            setSavingTask(true);

            const teamsLink = await TeamsService.getDeepLinkToMessage(selectedMessage, rtcContext.teamsContext);
            
            let response = false;

            if(taskDestination.Destination === ExportDestination.Todo){
                
                const title = `${selectedMessage.messageId} (${selectedMessage.messageTitle})`;

                const notes = `${taskNotes}<br /><a href='${teamsLink}'>Link to the message</a>`;

                response = await ToDoService.saveTodo(title, notes, taskDueDate, taskDestination.Id);

                if(response){
                    dispatchToast(
                        <Toast>
                          <ToastTitle>{rtcContext.localization.WebParts.OperationMessages.todoTaskSuccess}</ToastTitle>
                        </Toast>,
                        { intent: 'success' }
                    );
                }else{
                    dispatchToast(
                        <Toast>
                          <ToastTitle>{rtcContext.localization.WebParts.OperationMessages.todoTaskError}</ToastTitle>
                        </Toast>,
                        { intent: 'error' }
                    );
                }
            }else{
                const title = `${selectedMessage.messageId}: ${selectedMessage.messageTitle}`;

                const buckets = await PlannerService.getPlanBuckets(taskDestination.Id);

                const taskId = await PlannerService.savePlannerTask(taskDestination.Id, buckets[buckets.length-1].id, title, taskNotes, teamsLink, taskUsers, taskDueDate);
             
                if(taskId && taskId.trim().length > 0){
                    const newCommentPlanner = `${rtcContext.localization.WebParts.OperationMessages.taskCreatedInPlanner}<br/><a href='https://planner.cloud.microsoft/webui/v1/plan/${taskDestination.Id}/task/${taskId}?tid=${rtcContext.tenantId}' target='_blank'>${taskDestination.Title}</a>`;

                    await addComment(newCommentPlanner);

                    dispatchToast(
                        <Toast>
                          <ToastTitle>{rtcContext.localization.WebParts.OperationMessages.plannerTaskSuccess}</ToastTitle>
                        </Toast>,
                        { intent: 'success' }
                    );
                }else{
                    dispatchToast(
                        <Toast>
                          <ToastTitle>{rtcContext.localization.WebParts.OperationMessages.plannerTaskError}</ToastTitle>
                        </Toast>,
                        { intent: 'error' }
                    );
                }
            }

            setOpenCreateTaskDialog(false);
        }catch(error){
            console.error(error);

            dispatchToast(
                <Toast>
                  <ToastTitle>{rtcContext.localization.WebParts.Dialogs.genericErrorMessage}</ToastTitle>
                </Toast>,
                { intent: 'error' }
            );
        }

        setSavingTask(false);
    };

    const addComment = async(comment?: string): Promise<void> => {
        let commentToSave: string = newComment;

        if(comment && comment.trim().length > 0){
            commentToSave = comment;
        }

        let filteredMessagesClone = JSON.parse(JSON.stringify(filteredMessages));

        let found = true;

        let messageToUpdate = filteredMessagesClone.find((messageObj) => { return messageObj.messageId === selectedMessage.messageId });

        if(!messageToUpdate){
            found = false;

            filteredMessagesClone = JSON.parse(JSON.stringify(messages));

            messageToUpdate = filteredMessagesClone.find((messageObj) => { return messageObj.messageId === selectedMessage.messageId });
        }        

        if(messageToUpdate.messageComments === undefined || !found){
            messageToUpdate.messageComments = await CommentsService.getCommentsForMessage(messageToUpdate.messageId, rtcContext.siteUrl, rtcContext.sitePath, rtcContext.siteId);
        }

        messageToUpdate.messageComments.unshift({
            commentContent: commentToSave,
            commentCreatedBy: rtcContext.userDisplayName,
            commentDateCreated: moment().format("DD.MM.YYYY"),
            commentTimeCreated: moment().format("HH.mm")
        });

        setSelectedMessage(messageToUpdate);

        found ? setFilteredMessages(filteredMessagesClone) : setMessages(filteredMessagesClone);
    
        await CommentsService.saveCommentsForMessage(selectedMessage.messageId, commentToSave, rtcContext.siteUrl, rtcContext.sitePath);
        
        setNewComment('');  
    };    

    const copyLink = async(message: MessageItem): Promise<void> => {
        const link = await TeamsService.getDeepLinkToMessage(message, rtcContext.teamsContext);

        await navigator.clipboard.writeText(link);

        dispatchToast(
            <Toast>
              <ToastTitle>{rtcContext.localization.CommonStrings.LinkCopied}</ToastTitle>
            </Toast>,
            { intent: 'success' }
        );
    };

    const archive = async(): Promise<void> => {
        if(newComment && newComment.length > 0){
            await addComment();
        }       

        let ok = true;

        if(props.license.Type !== LicenseType.FreeTrial){
            ok = await MessageService.upsertMessageItem(partitionKey, selectedMessage.messageId, 'Archived', moment().toDate(), selectedMessage.isRead, moment(selectedMessage.messageDateCreated, 'DD.MM.YYYY').toDate(), props.subscriptionKey);
        }

        if(ok){
            const messageAux = JSON.parse(JSON.stringify(selectedMessage));

            messageAux.messageStatus = 'Archived';

            setSelectedMessage(messageAux);

            updateTenantItemState(messageAux.messageId, messageAux.isRead, messageAux.messageStatus);

            const messagesClone = JSON.parse(JSON.stringify(messages));
            const messageClone = messagesClone.find(messageObj => messageObj.messageId === selectedMessage.messageId);

            if(messageClone){
                messageClone.messageStatus = 'Archived';

                messageClone.comments = await CommentsService.getCommentsForMessage(selectedMessage.messageId, rtcContext.siteUrl, rtcContext.sitePath, rtcContext.siteId);

                setSelectedMessage(messageClone);
            }

            setMessages(messagesClone);

            const filteredMessagesClone = JSON.parse(JSON.stringify(filteredMessages));
            const filteredMessageClone = filteredMessagesClone.findIndex(messageObj => messageObj.messageId === selectedMessage.messageId);
    
            if(filteredMessageClone > -1){
                filteredMessagesClone.splice(filteredMessageClone, 1);
            }

            setFilteredMessages(filteredMessagesClone);

            dispatchToast(
                <Toast>
                  <ToastTitle>{rtcContext.localization.WebParts.OperationMessages.archiveSuccess}</ToastTitle>
                </Toast>,
                { intent: 'success' }
            );           

            setOpenShareMessageDialog(false);
        }else{
            const messageAux = JSON.parse(JSON.stringify(selectedMessage));

            messageAux.messageStatus = selectedMessage.messageStatus;

            setSelectedMessage(messageAux);

            dispatchToast(
                <Toast>
                  <ToastTitle>{rtcContext.localization.WebParts.OperationMessages.archiveError}</ToastTitle>
                </Toast>,
                { intent: 'error' }
            );
        }

        setNewComment('');

        setOpenArchiveDialog(false);
    };

    const cancelArchive = (): void => {
        const messageAux = JSON.parse(JSON.stringify(selectedMessage));

        messageAux.messageStatus = previousStatus;

        setSelectedMessage(messageAux);

        setNewComment('');

        setOpenArchiveDialog(false);
    };

    const shareMessage = async(): Promise<void> => {
        setSavingCard(true);

        const ok = await TeamsService.sendMessageToTeams(selectedMessage, selectedChannel.id, cardDescription, mentions, rtcContext.teamsContext);

        if(ok){
            dispatchToast(
                <Toast>
                  <ToastTitle>{rtcContext.localization.WebParts.Dialogs.shareConfirmationMessage}</ToastTitle>
                </Toast>,
                { intent: 'success' }
            );

            setOpenShareMessageDialog(false);
        }else{
            dispatchToast(
                <Toast>
                  <ToastTitle>{rtcContext.localization.WebParts.OperationMessages.shareToTeamsError}</ToastTitle>
                </Toast>,
                { intent: 'error' }
            );
        }

        setSavingCard(false);
    };

    const shareToTeams = async(message: MessageItem): Promise<void> => {
        setOpenShareMessageDialog(true);

        setSavingCard(false);

        setCardDescription('');

        try{
            setSelectedMessage(message);

            const channels = await TeamsService.getTeamChannels(rtcContext.groupId);

            if(channels && channels.length > 0){
                await loadMentions(rtcContext.teamsContext.team, channels[0].id, channels[0].name);

                setSelectedChannel(channels[0]);

                setChannels(channels);
            }            
        }catch(error){
            console.error(error);
        }
    };

    const shareToPlanner = async(): Promise<void> => {
        try{
            setOpenCreateTaskDialog(true);

            const plans = await PlannerService.getPlans(rtcContext.groupId);

            const todos = await ToDoService.getTodos();

            const destinationOptions: IExportTaskDestination[] = plans.concat(todos);

            setTaskDestinationOptions(destinationOptions);

            setTaskDestination(destinationOptions[0]);
        }catch(error){
            console.error(error);
        }
    };

    const copyLinkFromMenu = async(messageId: string): Promise<void> => {
        const message: MessageItem = messages.find(message => message.messageId.toLowerCase() === messageId.toLowerCase());

        if(message){
            await copyLink(message);
        }else{
            dispatchToast(
                <Toast>
                  <ToastTitle>{rtcContext.localization.WebParts.OperationMessages.copyLinkError}</ToastTitle>
                </Toast>,
                { intent: 'error' }
            );
        }
    };

    const archiveFromMenu = async(messageId: string): Promise<void> => {
        const message: MessageItem = messages.find(message => message.messageId.toLowerCase() === messageId.toLowerCase());

        if(message){
            setNewComment('');

            setSelectedMessage(message);

            setOpenArchiveDialog(true);
        }else{
            dispatchToast(
                <Toast>
                  <ToastTitle>{rtcContext.localization.WebParts.OperationMessages.archiveError}</ToastTitle>
                </Toast>,
                { intent: 'error' }
            );
        }
    };

    const shareToTeamsFromMenu = async(messageId: string): Promise<void> => {
        const message: MessageItem = messages.find(message => message.messageId.toLowerCase() === messageId.toLowerCase());

        if(message){
            await shareToTeams(message);
        }else{
            dispatchToast(
                <Toast>
                  <ToastTitle>{rtcContext.localization.WebParts.OperationMessages.shareToTeamsError}</ToastTitle>
                </Toast>,
                { intent: 'error' }
            );
        }
    };

    const renderComment = (comment: MessageCommentItem): JSX.Element => {
        return(
            <StackShim>
                <div style={{display: 'flex', alignItems: 'center', marginTop: 15}}>
                    <Comment20Regular style={{marginRight: 15}} />
                    <StackShim>
                        <Label>{comment.commentCreatedBy}</Label>
                        <Label>{`${comment.commentDateCreated} ${comment.commentTimeCreated}`}</Label>                        
                    </StackShim>
                </div>
                <div style={{marginTop: 5}} dangerouslySetInnerHTML={{__html: sanitize(comment.commentContent).replace(/<a /gmi, `<a target="_blank" `)}} />            
                {/* <Label style={{marginTop: 8}}>{comment.commentContent}</Label> */}
            </StackShim>
        );
    };

    const renderActionDetail = (actionCategory: string, actionText: string, severity: string): JSX.Element => {
        return(
            <div style={{display: 'inline-flex', marginBottom: 20, padding: 10, border: '1px solid #F8F8F8', boxShadow: '0px 0.6px 1.8px rgba(0, 0, 0, 0.25)',}}>
                <div style={{display: 'grid', paddingRight: 20, paddingLeft: 10, alignSelf: 'center', height: 46}}>
                    <Label className={styles.badgeLabelStyle}>{actionCategory}</Label>
                    {ReactHelper.renderBadge(severity, rtcContext.localization)}
                </div>
                <Label style={{paddingLeft: 10, borderLeft: '1px solid #F8F8F8', width: 400}}>{actionText}</Label>
            </div>);
    };

    const renderMessageDetails = (): JSX.Element => {
        let messageStatus = rtcContext.localization.WebParts.Dashboard.inboxStatus;

        if(selectedMessage.messageStatus === 'Review'){
            messageStatus = rtcContext.localization.WebParts.Dashboard.inReviewStatus;
        }else if(selectedMessage.messageStatus === 'Archived'){
            messageStatus = rtcContext.localization.WebParts.Dashboard.archivedStatus;
        }

        return(
            <div style={{display: 'inline-grid'}}>
                <Label>{`${rtcContext.localization.WebParts.Dashboard.publishedOn} ${selectedMessage.messageDatePublished}`}</Label>
                <div style={{display: 'flex'}}>
                    <div style={{paddingRight: 25, width: '66%', maxWidth: '66%'}}>
                        <Divider style={{marginTop: 20, marginBottom: 16}}>{rtcContext.localization.WebParts.MessagePanel.summary}</Divider>                        
                        {(selectedMessage.messageDescription && selectedMessage.messageDescription.length > 0) && <div dangerouslySetInnerHTML={{__html: sanitize(selectedMessage.messageDescription.replace(/style="width:.*?"/gmi,'style="width: 500px"')).toString().replace(/<a /gmi, `<a target="_blank" style="color: ${rtcContext.themeV9.colorBrandForegroundLink};"`)}} />}
                        <Divider style={{marginTop: 20, marginBottom: 16}}>{rtcContext.localization.WebParts.MessagePanel.roleRecommendations}</Divider>
                        {renderActionDetail('IT Admin', selectedMessage.messageITAdminText, selectedMessage.messageITAdminAction)}
                        {renderActionDetail('App Owner', selectedMessage.messageAppOwnerText, selectedMessage.messageAppOwnerAction)}
                        {renderActionDetail('Security', selectedMessage.messageSecurityText, selectedMessage.messageSecurityAction)}
                        {(props.license.Type === LicenseType.Compliance || props.license.Type === LicenseType.FreeTrial) && renderActionDetail('Compliance', selectedMessage.messageComplianceText, selectedMessage.messageComplianceAction)}
                        {props.license.Type !== LicenseType.Basic && <><Divider style={{marginTop: 20, marginBottom: 16}}>{selectedMessage.messageComments && selectedMessage.messageComments.length > 0 ? `${selectedMessage.messageComments.length} ` : ''}{rtcContext.localization.WebParts.MessagePanel.comments}</Divider>
                        <div style={{display: 'flex', alignItems: 'center', marginBottom: 5}}>
                            <Input type='text' disabled={openArchiveDialog || openCreateTaskDialog} key='txtComment' placeholder='Add a comment' style={{width: '100%', marginRight: 10}} value={newComment} onChange={(ev, data) => setNewComment(data.value)} />
                            <Button appearance='subtle' disabled={!newComment || newComment.length === 0} icon={<Send20Regular />} onClick={() => addComment()} />
                        </div>                
                        {(selectedMessage.messageComments && selectedMessage.messageComments.length > 0) && 
                            <>
                                {selectedMessage.messageComments.map((comment) => {
                                    return(renderComment(comment));
                                })}
                            </>
                        }</>}
                        <Divider style={{marginTop: 20, marginBottom: 16}}>{rtcContext.localization.WebParts.MessagePanel.originalMSMessage}</Divider>
                        {(selectedMessage.messageOriginalMSMessage && selectedMessage.messageOriginalMSMessage.length > 0) && <div dangerouslySetInnerHTML={{__html: sanitize(`<b>${selectedMessage.messageTitle}</b><br/><br/>${selectedMessage.messageOriginalMSMessage.replace(/style="width:.*?"/gmi,'style="width: 500px"')}`).toString().replace(/<a /gmi, `<a target="_blank" style="color: ${rtcContext.themeV9.colorBrandForegroundLink};"`)}} />}
                    </div>
                    <Divider vertical />
                    <div style={{paddingLeft: 25, overflowY: 'hidden', width: '34%'}}>
                        <Label style={{marginTop: 6}}>{rtcContext.localization.WebParts.Dialogs.status}</Label>
                        <br />
                        <Combobox value={messageStatus} selectedOptions={[(selectedMessage.messageStatus && selectedMessage.messageStatus.length > 0) ? selectedMessage.messageStatus.replace(/\s/g, "") : 'Inbox']} style={{marginBottom: 10}} onOptionSelect={onStatusSelect}>
                            <Option key='inboxKey' text={rtcContext.localization.WebParts.Dashboard.inboxStatus} value='Inbox'>{rtcContext.localization.WebParts.Dashboard.inboxStatus}</Option>
                            {props.license.Type !== LicenseType.Basic && <Option key='inReviewKey' text={rtcContext.localization.WebParts.Dashboard.inReviewStatus} value='InReview'>{rtcContext.localization.WebParts.Dashboard.inReviewStatus}</Option>}
                            <Option key='archivedKey' text={rtcContext.localization.WebParts.Dashboard.archivedStatus} value='Archived'>{rtcContext.localization.WebParts.Dashboard.archivedStatus}</Option>
                        </Combobox>
                        {props.license.Type !== LicenseType.Basic && <div style={{marginBottom: 15}}>
                            <Tooltip withArrow content={rtcContext.localization.CommonStrings.ShareInTeams} relationship="label"><MenuButton appearance='subtle' icon={<Icon iconName='TeamsLogo' style={{fontSize: 18}} />} onClick={() => shareToTeams(selectedMessage)} style={{marginRight: 10}} /></Tooltip>
                            <Tooltip withArrow content={rtcContext.localization.CommonStrings.CopyLink} relationship="label"><MenuButton appearance='subtle' icon={<Link20Regular />} onClick={() => copyLink(selectedMessage)} /></Tooltip>
                            <Tooltip withArrow content={rtcContext.localization.WebParts.Tooltips.createTask} relationship="label"><MenuButton appearance='subtle' icon={<ClipboardTaskAdd20Regular />} onClick={() => shareToPlanner()} style={{marginLeft: 10}} /></Tooltip>
                        </div>}
                        <div style={{display: 'inline-grid', marginBottom: 15}}>
                            <Label>{rtcContext.localization.WebParts.MessagePanel.timeline}</Label>
                            <div style={{marginTop: 10}} dangerouslySetInnerHTML={{__html: sanitize(selectedMessage.messageTimeline)}} />
                        </div>
                        <StackShim>
                            <Label>{rtcContext.localization.WebParts.Dashboard.servicesGroup}</Label>
                            {selectedMessage.messageServices.map((service) => {
                                return (<Label key={service} style={{marginTop: 5, fontWeight: 600}}>{service}</Label>);
                            })}
                            {(selectedMessage.messageRoadmapId && selectedMessage.messageRoadmapId.length > 0) &&
                                <>
                                    <Label style={{marginTop: 20}}>{rtcContext.localization.WebParts.MessagePanel.roadmapId}</Label>
                                    <Label style={{marginTop: 5, fontWeight: 600}}>{selectedMessage.messageRoadmapId}</Label>
                                </>
                            }
                        </StackShim>                        
                    </div>
                </div>
            </div>
        );
    };

    const renderViewOption = (viewItem: ViewItem): JSX.Element => {
        return (<MenuItem icon={viewItem.viewId === -1 ? <List20Regular /> : <Wand20Regular />} onClick={() => {setSelectedView(viewItem)}}>{viewItem.viewName}</MenuItem>);
    };

    const getActionName = (action: ViewRoleAssignment): string => {
        let severity: string = 'Ignore';

        switch(action.action){
            case MessageRoleAction.note:
                severity = 'Note';
                break;
            case MessageRoleAction.consider:
                severity = 'Consider';
                break;
            case MessageRoleAction.act:
                severity = 'Act';
                break;
        }

        return severity;
    };

    const renderRoleRefiners = (refiner: ViewRefiners, isViewEdit: boolean): JSX.Element => {
        let showActions: boolean = false;
        let actionToggle: (show: boolean) => void;

        switch(refiner.refinerName){
            case 'IT Administrator':
                showActions = renderITAdminActionsRefiner;
                actionToggle = setRenderITAdminActionsRefiner;
                break;
            case 'App owner':
                showActions = renderAppOwnerActionsRefiner;
                actionToggle = setRenderAppOwnerActionsRefiner;
                break;
            case 'Security':
                showActions = renderSecurityActionsRefiner;
                actionToggle = setRenderSecurityActionsRefiner;
                break;
            case 'Compliance':
                showActions = renderComplianceActionsRefiner;
                actionToggle = setRenderComplianceActionsRefiner;
                break;
        }

        return(<>
            <StackShim>
                <div style={{display: 'block', width: 200}}>
                    {/* {isViewEdit && <Checkbox label={refiner.refinerName} style={{width: 165}} checked={refinerCheck} onChange={(ev, data) => onRefinerChange(refiner.refinerName, data.checked as boolean, isViewEdit) } />} */}
                    <Label style={{top: 4, position: 'relative', float: 'left'}}>{refiner.refinerName}</Label>
                    <Button
                        appearance='subtle'
                        icon={!showActions ? <ChevronDown20Regular /> : <ChevronUp20Regular />}
                        onClick={() => actionToggle(!showActions)}
                    />
                </div>                
                {showActions && <div style={{display: 'grid', paddingLeft: 30}}>
                    {refiner.actions.map((action) => {
                        return(<Checkbox key={`${refiner.refinerName}_${action.action}`} label={getActionName(action)} checked={action.checked} onChange={(ev, data) => onViewRolesChange(isViewEdit, refiner, action, data.checked as boolean)} />)
                    })}
                </div>}
            </StackShim>            
        </>);
    };

    let bgLogoColor = "#fff";
	let bgLogoUrl = "";

	if (siteLogo === null || siteLogo === undefined || siteLogo === "") {
		bgLogoColor = siteColor;
	}
	else {
		bgLogoUrl = 'url("' + siteLogo + '")';
	}

    return(
        <>       
            <FluentProvider theme={rtcContext.themeV9}>
                <Dialog open={openArchiveDialog}>
                    <DialogSurface>
                        <DialogBody>
                            <DialogTitle>{rtcContext.localization.WebParts.Dialogs.archiveConfirmationTitle}</DialogTitle>
                            <DialogContent>
                                {props.license.Type !== LicenseType.Basic && <Input type='text' key='txtArchiveComment' value={newComment} multiple style={{width: '100%'}} onChange={(ev, data) => setNewComment(data.value)} placeholder={rtcContext.localization.WebParts.Dialogs.archiveConfirmationComment}/>}
                            </DialogContent>
                            <DialogActions style={{marginTop: 30}}>
                                <Button appearance="primary" onClick={() => archive()}>{rtcContext.localization.CommonStrings.Archive}</Button>
                                <DialogTrigger disableButtonEnhancement>                                    
                                    <Button appearance="secondary" onClick={() => cancelArchive()}>{rtcContext.localization.CommonStrings.Cancel}</Button>
                                </DialogTrigger>
                            </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>
                <Dialog open={openShareMessageDialog}>
                    <DialogSurface>
                        <DialogBody>
                            <DialogTitle>{rtcContext.localization.WebParts.Dialogs.shareInTeams}</DialogTitle>
                            <DialogContent>
                                <div>
                                    {(!channels || channels.length === 0) && <Spinner />}
                                    {(channels && channels.length > 0) && <div style={{display: 'grid'}}>                                        
                                        <Label style={{marginBottom: 5, marginTop: 15}}>{rtcContext.localization.WebParts.Dialogs.selectChannel}</Label>
                                        <Combobox defaultValue={selectedChannel.name} defaultSelectedOptions={[selectedChannel.id]} onOptionSelect={onChannelSelect}>
                                            {channels.map((channel) => {
                                                return(<Option value={channel.id} key={channel.id} text={channel.name}>{channel.name}</Option>);
                                            })}
                                        </Combobox>
                                        <Label style={{marginBottom: 5, marginTop: 15}}>{rtcContext.localization.WebParts.Dialogs.typeMessage}</Label>
                                        <Textarea appearance="outline" value={cardDescription} onChange={(ev, data) => { setCardDescription(data.value); }} />
                                        <Label style={{marginBottom: 5, marginTop: 15}}>{rtcContext.localization.WebParts.Dialogs.mentionTypes}</Label>
                                        <Combobox
                                            value={mentionsValue}                      
                                            disabled={!mentionOptions || mentionOptions.length === 0}
                                            freeform
                                            multiselect
                                            onChange={onChangeMention}
                                            onOptionSelect={onMentionOptionSelect}
                                            onBlur={onBlurMention}
                                            placeholder={rtcContext.localization.WebParts.Dialogs.mention}>                        
                                            {(matchingOptions && matchingOptions.length > 0) && matchingOptions.map((option) => {
                                                switch(option.type){
                                                    case MentionType.Member:
                                                        return(
                                                            <Option value={option.key} key={option.key} text={option.label}>
                                                                <Persona
                                                                    avatar={{ color: "colorful", "aria-hidden": true }}
                                                                    name={option.label}
                                                                    />
                                                            </Option>);
                                                    case MentionType.Team:
                                                        return(<Option value={option.key} key={option.key} text={option.label}><PeopleTeam20Regular style={{marginLeft: 5}} /> {option.label}</Option>);
                                                    case MentionType.Channel:
                                                        return(<Option value={option.key} key={option.key} text={option.label}><Channel20Regular style={{marginLeft: 5}} /> {option.label}</Option>);
                                                    case MentionType.Tag:
                                                        return(<Option value={option.key} key={option.key} text={option.label}><Tag20Regular style={{marginLeft: 5}} /> {option.label}</Option>);
                                                    default:
                                                        return(<Option value={option.key} key={option.key}>{option.label}</Option>);
                                                }                                
                                            })}
                                        </Combobox>  
                                    </div>}
                                </div>                              
                            </DialogContent>
                            <DialogActions style={{marginTop: 30}}>
                                <Button appearance="primary" disabled={savingCard} onClick={() => shareMessage()}>{rtcContext.localization.CommonStrings.Share}</Button>
                                <DialogTrigger disableButtonEnhancement>                                    
                                    <Button appearance="secondary" onClick={() => setOpenShareMessageDialog(false)}>{rtcContext.localization.CommonStrings.Cancel}</Button>
                                </DialogTrigger>
                            </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>
                <Dialog open={openCreateTaskDialog}>
                    <DialogSurface>
                        <DialogBody>
                            <DialogTitle>{rtcContext.localization.WebParts.Dialogs.taskPanelTitle}</DialogTitle>
                            <DialogContent>
                            {(!taskDestinationOptions || taskDestinationOptions.length === 0) && <Spinner />}
                            {(taskDestinationOptions && taskDestinationOptions.length > 0) && 
                                <div>                                    
                                    <div>
                                        <div style={{display: 'grid'}}>
                                            <Label style={{marginBottom: 5, marginTop: 15}}>{rtcContext.localization.WebParts.Tooltips.createIn}</Label>
                                            <Combobox defaultSelectedOptions={[taskDestinationOptions[0].Id]} defaultValue={taskDestinationOptions[0].Title} onOptionSelect={onTaskDestinationSelect}>                                                
                                                {taskDestinationOptions.filter(option => option.Destination === ExportDestination.Todo).length > 0 && <OptionGroup label='ToDo'>
                                                    {taskDestinationOptions.filter(option => option.Destination === ExportDestination.Todo).map((taskDestination) => {
                                                        return (<Option key={taskDestination.Id} value={taskDestination.Id} text={taskDestination.Title}><TaskListLtr20Regular style={{marginLeft: 5}} />{taskDestination.Title}</Option>);                                                   
                                                    })}
                                                </OptionGroup>}                                                
                                                {taskDestinationOptions.filter(option => option.Destination === ExportDestination.Planner).length > 0 && <OptionGroup label='Planner'>
                                                    {taskDestinationOptions.filter(option => option.Destination === ExportDestination.Planner).map((taskDestination) => {
                                                        return (<Option key={taskDestination.Id} value={taskDestination.Id} text={taskDestination.Title}>
                                                            <div style={{backgroundColor: bgLogoColor,
                                                                backgroundImage: bgLogoUrl,
                                                                width: 20,
                                                                height: 20,
                                                                borderRadius: 4,
                                                                backgroundSize: 'cover',
                                                                marginLeft: 5}} 
                                                                />{taskDestination.Title}</Option>);
                                                    })}
                                                </OptionGroup>}                                                                                              
                                            </Combobox> 
                                        </div>
                                        <div style={{display: 'grid'}}>
                                            <DatePicker style={{marginBottom: 5, marginTop: 15}} formatDate={(date) => { return moment(date).format('DD.MM.YYYY').toString();}} placeholder={rtcContext.localization.WebParts.Dialogs.dueDate} value={taskDueDate} onSelectDate={(date) => { setTaskDueDate(date); }} />
                                        </div>
                                    </div>
                                    {(taskDestination && taskDestination.Destination === ExportDestination.Planner) &&<div style={{display: 'grid'}}>
                                        <Label style={{marginBottom: 5}}>{rtcContext.localization.CommonStrings.Assign}</Label>
                                        <PeoplePicker
                                            label=""
                                            allowDuplicate={false}
                                            onChange={(users) => setTaskUsers(users)}
                                            isRequired={false}
                                            resolveEmailAdresses={false}
                                            limitToCurrentO365Group={true}
                                        />
                                    </div>}
                                    <div style={{display: 'grid'}}>
                                        <Label style={{marginBottom: 5, marginTop: 15}}>{rtcContext.localization.WebParts.Dialogs.notes}</Label>
                                        <Textarea value={taskNotes} onChange={(ev, data) => setTaskNotes(data.value)} /> 
                                    </div>
                                </div>}                                                            
                            </DialogContent>
                            <DialogActions style={{marginTop: 30}}>
                                <Button appearance="primary" disabled={savingTask} onClick={() => saveTask()}>{rtcContext.localization.CommonStrings.Create}</Button>
                                <DialogTrigger disableButtonEnhancement>                                    
                                    <Button appearance="secondary" onClick={() => setOpenCreateTaskDialog(false)}>{rtcContext.localization.CommonStrings.Close}</Button>
                                </DialogTrigger>
                            </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>
                <OverlayDrawer
                    style={{paddingBottom: 15}}
                    position='end'                    
                    size='large'
                    open={openMessagePanel}
                    onOpenChange={(_, { open }) => setOpenMessagePanel(open)}>                    
                    <DrawerHeader>
                        <DrawerHeaderTitle
                            className={styles.drawerTitle}
                            action={
                                <>
                                    {selectedMessage && <>
                                    <Button
                                        appearance="subtle"
                                        disabled={selectedMessageIndex === 0}
                                        aria-label="PreviousMessage"
                                        icon={<ArrowUp24Regular />}
                                        onClick={() => openMessage(selectedMessageIndex-1)}
                                    />
                                    <Button
                                        appearance="subtle"
                                        disabled={selectedMessageIndex === filteredMessages.length-1}
                                        aria-label="NextMessage"
                                        icon={<ArrowDown24Regular />}
                                        onClick={() => openMessage(selectedMessageIndex+1)}
                                    />
                                    <Button
                                        appearance="subtle"
                                        aria-label="Close"
                                        icon={<Dismiss24Regular />}
                                        onClick={() => setOpenMessagePanel(false)}
                                    />
                                    </>}
                                </>                          
                            }
                        >
                            {selectedMessage && `${selectedMessage.messageId} | ${selectedMessage.messageRTCTitle}`}
                        </DrawerHeaderTitle>
                    </DrawerHeader>
                    <DrawerBody>
                        {!selectedMessage && <Spinner />}
                        {selectedMessage && renderMessageDetails()}                       
                    </DrawerBody>
                </OverlayDrawer>
                {editableView && <OverlayDrawer
                    position='end'                    
                    size='medium'
                    open={openViewPanel}
                    onOpenChange={(_, { open }) => setOpenViewPanel(open)}>                    
                    <DrawerHeader>
                        <DrawerHeaderTitle
                            action={
                                <Button
                                    appearance="subtle"
                                    aria-label="Close"
                                    icon={<Dismiss24Regular />}
                                    onClick={() => setOpenViewPanel(false)}
                                />                         
                            }
                        >
                            {viewEditMode ? rtcContext.localization.WebParts.Dialogs.customViewPanelEditTitle : rtcContext.localization.WebParts.Dialogs.customViewPanelNewTitle}
                        </DrawerHeaderTitle>
                    </DrawerHeader>
                    <DrawerBody>
                        <StackShim>
                            <Label>{rtcContext.localization.WebParts.Dialogs.customViewPanelDescription}</Label>
                            <Label style={{fontWeight: 600, marginTop: 10, marginBottom: 5}} required>{rtcContext.localization.WebParts.Dialogs.viewName}</Label>
                            <Input type='text' value={editableView.viewName} onChange={(ev, data) => { onViewNameChange(data.value)}} />
                            <Divider style={{marginTop: 25, marginBottom: 10}} />
                            <Label style={{fontWeight: 600}}>{rtcContext.localization.WebParts.Dashboard.rolesGroup}</Label>
                            {(editableViewRefiners && editableViewRefiners.length > 0) &&
                                editableViewRefiners.map((refiner) => {
                                    if(props.license.Type === LicenseType.Compliance || props.license.Type === LicenseType.FreeTrial || refiner.refinerName !== 'Compliance'){
                                        return(renderRoleRefiners(refiner, true));
                                    }
                                })
                            }
                            <Divider style={{marginTop: 15, marginBottom: 5}} />
                            <Label style={{fontWeight: 600, marginBottom: 15}}>{rtcContext.localization.WebParts.Dashboard.servicesGroup}</Label>
                            {(loadedServices && loadedServices.length > 0) &&
                                loadedServices.map((service) => {
                                    //return (<Checkbox key={`chk_${service}`} label={service} onChange={(ev, data) => onViewServiceChange(data.checked as boolean, service)} />);
                                    return (<Checkbox key={`chk_${service}`} label={service} checked={editableView.services.find((vService) => { return vService === service }) !== undefined } onChange={(ev, data) => onViewServiceChange(data.checked as boolean, service)} />);
                                })
                            }
                        </StackShim>
                    </DrawerBody>
                    <DrawerFooter style={{display: 'inline-block'}}>
                        <Button appearance="primary" disabled={!editableView.viewName || editableView.viewName.length === 0 || editableView.services.length === 0 || editableViewRefiners.every(vRefiner => !vRefiner.actions.some(rAction => rAction.checked))} onClick={() => saveView()}>{rtcContext.localization.CommonStrings.Save}</Button>
                        <Button appearance="secondary" style={{marginLeft: 20}} onClick={() => setOpenViewPanel(false)}>{rtcContext.localization.CommonStrings.Cancel}</Button>
                        {viewEditMode && <Button appearance="subtle" style={{float: 'right'}} icon={<Delete20Regular />} onClick={() => deleteView()}>{rtcContext.localization.CommonStrings.Delete}</Button>}
                    </DrawerFooter>
                </OverlayDrawer>}
                <Dialog open={openMissingTicketsDialog}>
                    <DialogSurface>
                        <DialogBody>
                            <DialogTitle>{rtcContext.localization.WebParts.Dialogs.missingAMessage}</DialogTitle>
                            <DialogContent>
                                <Input type='text' required contentAfter={<Search20Regular />} placeholder={rtcContext.localization.WebParts.Dialogs.missingPlaceholder} value={searchQueryMissingTickets} style={{width: '100%', marginBottom: 15}} onKeyPress={(ev) => keyPressSearchMissing(ev)} onChange={(ev, data) => {setSearchQueryMissingTickets(data.value)}} />
                                {(missingMessages && missingMessages.length > 0) && 
                                <>
                                <Label style={{fontWeight: 600}}>{rtcContext.localization.WebParts.Dialogs.status}</Label>
                                    <DataGrid
                                        items={missingMessages}
                                        columns={providerColumns}>
                                        <DataGridBody<MissingMessageItem>>
                                            {({ item, rowId }) => (
                                            <DataGridRow<MissingMessageItem> key={rowId}>
                                                {({ renderCell }) => (
                                                <DataGridCell>{renderCell(item)}</DataGridCell>
                                                )}
                                            </DataGridRow>
                                            )}
                                        </DataGridBody>
                                </DataGrid>
                                </>}
                            </DialogContent>
                            <DialogActions style={{marginTop: 30}}>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button appearance="secondary" onClick={() => setOpenMissingTicketsDialog(false)}>{rtcContext.localization.CommonStrings.Close}</Button>
                                </DialogTrigger>
                            </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>
                {(props.license.Type !== LicenseType.FreeTrial && (!props.subscriptionKey || props.subscriptionKey.length === 0)) && 
                <div style={{display: 'grid', justifyItems: 'center', top: '45%', left: '40%', position: 'absolute'}}>
                    <Label style={{display: 'flex', fontSize: 18, fontWeight: 700}}>{rtcContext.localization.PropertyPaneStrings.welcomeTitle}</Label>
                    <Label style={{display: 'flex', width: 365, textAlign: 'center', marginTop: 40, marginBottom: 30}}>{rtcContext.localization.PropertyPaneStrings.welcomeBody}</Label>
                </div>}
                {(props.license.Type === LicenseType.FreeTrial || (props.subscriptionKey && props.subscriptionKey.length > 0)) && 
                <div style={{padding: 20, overflow: 'hidden'}}>
                    {props.license.Type === LicenseType.FreeTrial && <MessageBar intent="info" style={{marginBottom: 20}}><MessageBarBody>{rtcContext.localization.WebParts.Dashboard.freeTrialDisclaimerP1}<Link href={`mailto:${AppConstants.runthecloudemail}?subject=${rtcContext.localization.PropertyPaneStrings.licenseEmailSubject}`} inline>{AppConstants.runthecloudemail}</Link>{rtcContext.localization.WebParts.Dashboard.freeTrialDisclaimerP2}</MessageBarBody></MessageBar>}
                    <div style={{display: 'flex'}}>                        
                        <div style={{width: '100%'}}>                            
                            <StackShim horizontal>
                                <StackShim>                                    
                                    <StackShim horizontal>
                                        <Label style={{fontWeight: 600, fontSize: 20}}>{rtcContext.localization.WebParts.Dashboard.dashboardTitle}</Label>
                                        {props.sharedMode && <Tooltip withArrow content={`${rtcContext.localization.PropertyPaneStrings.groupMode}: ${rtcContext.localization.WebParts.Tooltips.groupModeDescription}`} relationship="label"><PeopleTeam20Regular style={{marginLeft: 10}} /></Tooltip>}
                                        {!props.sharedMode && <Tooltip withArrow content={`${rtcContext.localization.PropertyPaneStrings.individualMode}: ${rtcContext.localization.WebParts.Tooltips.individualModeDescription}`} relationship="label"><Person20Regular style={{marginLeft: 10}} /></Tooltip>}
                                    </StackShim>
                                    <div style={{marginTop: 17}}>
                                        <Input type='text' required contentBefore={<Search20Regular />} style={{width: 605}} placeholder={rtcContext.localization.CommonStrings.Search} value={searchQuery} onKeyPress={(ev) => keyPressSearchMessages(ev)} onChange={(ev, data) => {setSearchQuery(data.value)}} />
                                        <Tooltip withArrow content={rtcContext.localization.WebParts.Tooltips.timeframe} relationship="label">
                                            <Combobox defaultSelectedOptions={['3']} defaultValue={rtcContext.localization.WebParts.Dashboard.last3Months} onOptionSelect={onCutoffDateSelect} style={{marginLeft: 20}}>
                                                <Option value='3' text={rtcContext.localization.WebParts.Dashboard.last3Months}>{rtcContext.localization.WebParts.Dashboard.last3Months}</Option>
                                                <Option value='6' text={rtcContext.localization.WebParts.Dashboard.last6Months}>{rtcContext.localization.WebParts.Dashboard.last6Months}</Option>
                                                <Option value='12' text={rtcContext.localization.WebParts.Dashboard.last12Months}>{rtcContext.localization.WebParts.Dashboard.last12Months}</Option>
                                                <Option value='18' text={rtcContext.localization.WebParts.Dashboard.last18Months}>{rtcContext.localization.WebParts.Dashboard.last18Months}</Option>
                                                <Option value='24' text={rtcContext.localization.WebParts.Dashboard.last24Months}>{rtcContext.localization.WebParts.Dashboard.last24Months}</Option>
                                                <Option value='30' text={rtcContext.localization.WebParts.Dashboard.last30Months}>{rtcContext.localization.WebParts.Dashboard.last30Months}</Option>
                                            </Combobox>
                                        </Tooltip>
                                    </div>                                    
                                    <TabList selectedValue={tabSelected} onTabSelect={onTabSelect}>
                                        <Tab id="Inbox" value="inbox">
                                            {rtcContext.localization.WebParts.Dashboard.inboxStatus}
                                        </Tab>
                                        {props.license.Type !== LicenseType.Basic && <Tab id="Review" value="review">
                                            {rtcContext.localization.WebParts.Dashboard.inReviewStatus}
                                        </Tab>}
                                        <Tab id="Archived" value="archived">
                                            {rtcContext.localization.WebParts.Dashboard.archivedStatus}
                                        </Tab>
                                    </TabList>
                                </StackShim>
                                <div style={{display: 'flex', right: 60, position: 'absolute'}}>
                                    {(selectedView && viewItems && viewItems.length > 0) && 
                                    <Menu>
                                        <MenuTrigger disableButtonEnhancement>
                                            <MenuButton appearance="subtle" style={{height: 20, paddingRight: 15, borderRight: '1px solid #BDBDBD'}} icon={selectedView.viewId === -1 ? <List20Regular /> : <Wand20Regular />}>{selectedView.viewName}</MenuButton>
                                        </MenuTrigger>
                                        <MenuPopover>
                                            <MenuList>
                                                {viewItems.map((viewItem) => { return (renderViewOption(viewItem));})}                                                
                                                <MenuItem icon={<Add20Regular />} onClick={() => viewMode(false)}>{rtcContext.localization.WebParts.Dashboard.addView}</MenuItem>
                                                <MenuItem disabled={selectedView.viewId === -1} icon={<Edit20Regular />} onClick={() => viewMode(true)}>{rtcContext.localization.WebParts.Dashboard.editView}</MenuItem>
                                            </MenuList>
                                        </MenuPopover>
                                    </Menu>}
                                    <Tooltip withArrow content={rtcContext.localization.WebParts.Dialogs.missingAMessage} relationship="label">
                                        <NumberSymbolSquare20Regular style={{marginLeft: 10}} onClick={() => {setMissingMessages([]); setSearchQueryMissingTickets(''); setOpenMissingTicketsDialog(true);}} />
                                    </Tooltip>
                                    <Tooltip withArrow content={rtcContext.localization.WebParts.Tooltips.support} relationship="label">
                                        <PersonSupport20Regular style={{marginLeft: 10}} onClick={() => {window.open(`mailto:${AppConstants.runthecloudemail}?subject=[${rtcContext.upn.split('@')[1].split('.')[0].toUpperCase()}] - ${rtcContext.localization.WebParts.Dashboard.supportMailSubject}&body=${rtcContext.localization.WebParts.Dashboard.supportMailDescription}`)}} />
                                    </Tooltip>
                                    {openViewRefiners && <Tooltip withArrow content={rtcContext.localization.WebParts.Tooltips.closePanel} relationship="label"><PanelLeftExpand20Regular style={{marginLeft: 10}} onClick={() => setOpenViewRefiners(false)} /></Tooltip>}
                                    {!openViewRefiners && <Tooltip withArrow content={rtcContext.localization.WebParts.Tooltips.openPanel} relationship="label"><PanelRightExpand20Regular style={{marginLeft: 10}} onClick={() => setOpenViewRefiners(true)} /></Tooltip>}
                                </div>
                            </StackShim>
                            {(props.subscriptionKey && props.subscriptionKey.length > 0 && isLoading) && <Spinner style={{paddingTop: 20}} />}
                            {(((props.subscriptionKey && props.subscriptionKey.length > 0) || props.license.Type === LicenseType.FreeTrial) && !isLoading) && 
                            <div style={{display: 'flex', height: '100%'}}>
                                <div style={{width: '100%'}}>
                                    {(filteredMessages && filteredMessages.length > 0) && 
                                        <div style={{width: '100%', display: 'inline-block'}}>
                                            {!messagesExpanded && <Tooltip withArrow content={rtcContext.localization.CommonStrings.ExpandAll} relationship="label"><AddSquareMultiple20Regular style={{float: 'right', marginBottom: 20}} onClick={() => setMessagesExpanded(true)} /></Tooltip>}
                                            {messagesExpanded && <Tooltip withArrow content={rtcContext.localization.CommonStrings.CollapseAll} relationship="label"><SubtractSquareMultiple20Regular style={{float: 'right', marginBottom: 20}} onClick={() => setMessagesExpanded(false)} /></Tooltip>}
                                            {filteredMessages.slice(30*(pageNumber-1), 30*(pageNumber-1)+29).map((message, index) => {
                                                return (<MessageCard key={message.messageId} rtcContext={rtcContext} index={index} message={message} license={props.license} themeV9={rtcContext.themeV9} expandCollapseCard={expandCollapseCard} archiveFromMenu={archiveFromMenu} copyLinkFromMenu={copyLinkFromMenu} shareToTeamsFromMenu={shareToTeamsFromMenu} openMessage={openMessage} />);
                                            })}
                                            <div className={`${paginationClassName.pagination} ${paginationClassName.paginationColor}`} style={{color: rtcContext.themeV9.colorBrandForegroundLink}}>
                                                <Pagination
                                                    activePage={pageNumber}
                                                    firstPageText={<Icon iconName="DoubleChevronLeft"/>}
                                                    lastPageText={<Icon iconName="DoubleChevronRight"/>}
                                                    prevPageText={<Icon iconName="ChevronLeft"/>}
                                                    nextPageText={<Icon iconName="ChevronRight"/>}
                                                    activeLinkClass={"active"}
                                                    itemsCountPerPage={30}
                                                    totalItemsCount={filteredMessages.length}
                                                    pageRangeDisplayed={5}
                                                    onChange={setPageNumber}
                                                    />
                                            </div>
                                            <Label style={{display: 'flex', marginBottom: 50, justifyContent: 'center'}}>{rtcContext.localization.WebParts.Dashboard.pagingResults.replace('{0}', filteredMessages.length.toString())}</Label>
                                        </div>                        
                                    }
                                    {(!filteredMessages || filteredMessages.length === 0) && <Label style={{display: 'flex', marginBottom: 50, justifyContent: 'center'}}>{rtcContext.localization.WebParts.Dashboard.noMessagesToShow}</Label>}
                                </div>                                
                                {(loadedServices && loadedServices.length > 0) && <InlineDrawer separator open={openViewRefiners} position='end' style={{marginLeft: 26, width: 275, top: -40, height: (1000+(loadedServices.length*36)), minHeight: (1000+(loadedServices.length*36))}}>
                                    <DrawerHeader style={{paddingTop: 0}}>
                                        <DrawerHeaderTitle
                                            action={
                                                <Tooltip withArrow content={rtcContext.localization.WebParts.Tooltips.resetView} relationship="label">
                                                    <Button
                                                        appearance="subtle"
                                                        aria-label="Reset"
                                                        icon={<ArrowReset24Regular />}
                                                        onClick={resetCustomRefiners}
                                                    />
                                                </Tooltip>
                                            }
                                            />
                                    </DrawerHeader>
                                    <DrawerBody>
                                        <Label style={{fontWeight: 600, marginTop: 10}}>{rtcContext.localization.WebParts.Dashboard.rolesGroup}</Label>
                                        <br />
                                        {(viewRefiners && viewRefiners.length > 0) &&
                                            viewRefiners.map((refiner) => {
                                                if(props.license.Type === LicenseType.Compliance || props.license.Type === LicenseType.FreeTrial || refiner.refinerName !== 'Compliance'){
                                                    return(renderRoleRefiners(refiner, false));
                                                }
                                            })
                                        }
                                        <Divider style={{marginTop: 15, marginBottom: 5}} />
                                        <Label style={{fontWeight: 600, marginBottom: 15}}>{rtcContext.localization.WebParts.Dashboard.servicesGroup}</Label>
                                            {loadedServices.map((service) => {
                                                return (<><br /><Checkbox key={`chk_${service}`} label={service} checked={viewServices.find((viewService) => { return viewService === service}) !== undefined} onChange={(ev, data) => onViewServiceRefinerChange(data.checked as boolean, service)} /></>);
                                            })}
                                    </DrawerBody>
                                </InlineDrawer>}
                            </div>}                                                      
                        </div>                         
                    </div>
                </div>}
                <Toaster toasterId={toasterId} />                           
            </FluentProvider>
        </>
    );
}