export type MessageItem = {
    messageId: string;
    messageTitle: string;
    messageRTCTitle: string;
    messageDescription?: string;
    messageDateCreated: string;
    messageDatePublished: string;
    messageDatePublishedRaw: string;
    messageStatus: string;
    messageServices: string[];
    messageRoadmapId: string;
    messageComments: MessageCommentItem[];
    messageITAdminAction: string;
    messageAppOwnerAction: string;
    messageSecurityAction: string;
    messageComplianceAction: string;
    messageITAdminText: string;
    messageAppOwnerText: string;
    messageSecurityText: string;
    messageComplianceText: string;
    messageOriginalMSMessage: string;
    messageTimeline: string;
    messageCategory: string;
    cardExpanded: boolean;
    isRead: boolean;
};

export type MessageCommentItem = {
    commentContent: string;
    commentDateCreated: string;
    commentTimeCreated: string;
    commentCreatedBy: string;
};

export enum MessageRoleAction {
    ignore = "0", 
    note = "1", 
    consider = "2", 
    act = "3"
}

export type MissingMessageItem = {
    messageId: string;
    messageStatus: string;
};

export type TenantMessageItem = {
    messageId: string;
    messageStatus: string;
    isRead: boolean;
    messageDate: string;
    statusDate: string;
    timeStamp?: string;
};