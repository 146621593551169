/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
    FluentProvider,
    Label,
    useToastController,
    Toast,
    ToastTitle,
    useId,
    MenuItem,
    Button,
    Checkbox,
    Toaster,
    MessageBar,
    MessageBarBody,
    Link,
    Tooltip,
    Input,
    Menu,
    MenuTrigger,
    MenuButton,
    MenuPopover,
    MenuList,
    Spinner,
    Option,
    OptionOnSelectData,
    Dropdown,
    Divider,
    Dialog,
    DialogSurface,
    DialogBody,
    DialogContent,
    DialogTitle,
    Combobox,
    ComboboxProps,
  } from "@fluentui/react-components";
import RTCContext from './RTCWrapper/RTCContext';
import { useContext, useEffect, useState } from "react";
import { IRtCMobileDashboardProps } from "../common/interfaces/IRtCDashboardProps";
import { MessageCommentItem, MessageItem, MessageRoleAction, TenantMessageItem } from "../common/interfaces/IMessageItem";
import MessageService from "../common/services/MessageService/MessageService";
import { LicenseType } from "../common/interfaces/ILicenseStatus";
import { sha256 } from "js-sha256";
import { ViewItem, ViewRefiners, ViewRoleAssignment } from "../common/interfaces/IViewItem";
import ViewsService from "../common/services/ViewsService/ViewService";
import { Icon, mergeStyleSets } from "@fluentui/react";
import CommentsService from "../common/services/CommentsService/CommentsService";
import moment from "moment";
import { ChevronDown20Regular, ChevronUp20Regular, Comment20Regular, Filter20Regular, List20Regular, PersonSupport20Regular, Search20Regular, Send20Regular, Wand20Regular } from "@fluentui/react-icons";
import { StackShim } from "@fluentui/react-migration-v8-v9";
import { AppConstants } from "../common/Constants/AppConstants";
import Pagination from "react-js-pagination";
import { MessageCardMobile } from "./MessageCard/MessageCardMobile";
import { sanitize } from "dompurify";
import { ReactHelper } from "../common/helpers/ReactHelper";

export const MobileView: React.FunctionComponent<IRtCMobileDashboardProps> = (props) => {
  const rtcContext = useContext(RTCContext);

  const [searchQuery, setSearchQuery] = useState<string>('');

  const [loadedServices, setLoadedServices] = useState<string[]>([]);
  const [messages, setMessages] = useState<MessageItem[]>([]);
  const [filteredMessages, setFilteredMessages] = useState<MessageItem[]>([]);
  const [viewItems, setViewItems] = useState<ViewItem[]>([]);
  const [viewRefiners, setViewRefiners] = useState<ViewRefiners[]>([]);
  const [editableViewRefiners, setEditableViewRefiners] = useState<ViewRefiners[]>([]);
  const [viewServices, setViewServices] = useState<string[]>([]);
  const [tenantMessageItems, setTenantMessageItems] = useState<TenantMessageItem[]>([]);

  const [pageNumber, setPageNumber] = useState<number>(1);

  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tabSelected, setTabSelected] = useState<string>('inbox');
  const [openMessagePanel, setOpenMessagePanel] = useState<boolean>(false);
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [selectedMessage, setSelectedMessage] = useState<MessageItem>(undefined);
  const [selectedView, setSelectedView] = useState<ViewItem>(undefined);
  const [editableView, setEditableView] = useState<ViewItem>(undefined);    

  const [newComment, setNewComment] = useState<string>('');

  const [renderITAdminActionsRefiner, setRenderITAdminActionsRefiner] = useState<boolean>(false);
  const [renderAppOwnerActionsRefiner, setRenderAppOwnerActionsRefiner] = useState<boolean>(false);
  const [renderSecurityActionsRefiner, setRenderSecurityActionsRefiner] = useState<boolean>(false);
  const [renderComplianceActionsRefiner, setRenderComplianceActionsRefiner] = useState<boolean>(false);

  const [partitionKey, setPartitionKey] = useState<string>('');

  const [cutoffMonths, setCutoffMonths] = useState<number>(3);

  //const styles = messageStyles();

  const toasterId = useId("breadToaster");
  const { dispatchToast } = useToastController(toasterId);

  const paginationClassName = mergeStyleSets({
    paginationColor:{
        selectors:{
            "ul li a":{
            color: rtcContext.themeV9.colorBrandForegroundLink
            }
        }
    },
    pagination:{
      textAlign: 'center',
      selectors: {
        'ul':{
          display: 'inline-block',
          paddingLeft: 0,
          margin: '20px 0',
          borderRadius: 4,
          selectors: {
            'li': {
              display: 'inline',
              selectors: {
                'a': {
                  float: 'left',
                  padding: '4px 8px',
                  textDecoration: 'none',
                  borderRadius: 15,
                  selectors: {
                    'i': {
                      fontSize: 11
                    }
                  }
                },
                'a:visited':{
                  color: 'inherit'
                }
              }
            },
            'li.active': {
                fontWeight: 800
            },
            ':global':{
              selectors:{
                'li.disabled': {
                  selectors:{
                    'a':{
                      color: rtcContext.themeV9.colorBrandForegroundLink,
                      selectors: {
                        'i': {
                          color: rtcContext.themeV9.colorBrandForegroundLink
                        }
                      }
                    }
                  }
                }                    
              }
            }
          }
        }
      }
    }
  });

  const setPartitionKeyValue = (): string => {
    let partitionKeyValue = `${rtcContext.siteId}_${rtcContext.upn}`;
    
    if(props.sharedMode){
        partitionKeyValue = rtcContext.siteId;
    }

    partitionKeyValue = sha256(partitionKeyValue);

    setPartitionKey(partitionKeyValue);

    return partitionKeyValue;
  };

  const loadViewRefiners = (editableView: boolean):void => {
    const refiners: ViewRefiners[] = [];

    refiners.push({
        refinerName: 'IT Administrator',
        actions: [{action: MessageRoleAction.ignore, checked: true}, {action: MessageRoleAction.note, checked: true}, {action: MessageRoleAction.consider, checked: true}, {action: MessageRoleAction.act, checked: true}]
    });

    refiners.push({
        refinerName: 'App owner',
        actions: [{action: MessageRoleAction.ignore, checked: true}, {action: MessageRoleAction.note, checked: true}, {action: MessageRoleAction.consider, checked: true}, {action: MessageRoleAction.act, checked: true}]
    });

    refiners.push({
        refinerName: 'Security',
        actions: [{action: MessageRoleAction.ignore, checked: true}, {action: MessageRoleAction.note, checked: true}, {action: MessageRoleAction.consider, checked: true}, {action: MessageRoleAction.act, checked: true}]
    });

    refiners.push({
        refinerName: 'Compliance',
        actions: [{action: MessageRoleAction.ignore, checked: true}, {action: MessageRoleAction.note, checked: true}, {action: MessageRoleAction.consider, checked: true}, {action: MessageRoleAction.act, checked: true}]
    });

    if(editableView){
        setEditableViewRefiners(refiners);
    }else{
        refiners.forEach((refiner) => {refiner.actions.forEach((action) => {action.checked = false})});

        setViewRefiners(refiners);
    }        
  };
  
  const loadViews = async():Promise<void> => {
    const views: ViewItem[] = [];

    views.push({
        viewId: -1,
        viewName: rtcContext.localization.WebParts.Dashboard.allItemsViewOption,
        itAdministratorAssignments: [{ action: MessageRoleAction.ignore, checked: false }, { action: MessageRoleAction.note, checked: false }, { action: MessageRoleAction.consider, checked: false }, { action: MessageRoleAction.act, checked: false }],
        appOwnerAssignments: [{ action: MessageRoleAction.ignore, checked: false }, { action: MessageRoleAction.note, checked: false }, { action: MessageRoleAction.consider, checked: false }, { action: MessageRoleAction.act, checked: false }],
        securityAssignments: [{ action: MessageRoleAction.ignore, checked: false }, { action: MessageRoleAction.note, checked: false }, { action: MessageRoleAction.consider, checked: false }, { action: MessageRoleAction.act, checked: false }],
        complianceAssignments: [{ action: MessageRoleAction.ignore, checked: false }, { action: MessageRoleAction.note, checked: false }, { action: MessageRoleAction.consider, checked: false }, { action: MessageRoleAction.act, checked: false }],
        services: []
        //services: loadedServices
    });

    try{
        const viewsFromShp: ViewItem[] = await ViewsService.getViewsForUser(rtcContext.siteUrl, rtcContext.sitePath, rtcContext.userDisplayName, rtcContext.siteId);
    
        if(viewsFromShp && viewsFromShp.length > 0){
            views.push(...viewsFromShp);
        }
    }catch(error){
        console.error('Couldn\'t load the RtC views');
        console.error(error);
    }        

    setViewItems(views);
  };
  
  const updateTenantItemState = (rowKey: string, read: boolean, status: string): void => {
      const clonedTenantItems = JSON.parse(JSON.stringify(tenantMessageItems));

      const messageItem = clonedTenantItems.find(item => item.messageId === rowKey);

      if(messageItem){
          if(read){
              messageItem.isRead = true;                
          }

          messageItem.messageStatus = status;
      }else{
          const newMessageItem: TenantMessageItem = {
              messageStatus: status,
              isRead: read,
              messageId: rowKey,
              messageDate: '',
              statusDate: ''
          }

          clonedTenantItems.push(newMessageItem);
      }

      setTenantMessageItems(clonedTenantItems);
  };

  const resetCustomRefiners = (): void => {
        const restoredRefiners: ViewRefiners[] = [];

        restoredRefiners.push({refinerName: 'IT Administrator', actions:selectedView.itAdministratorAssignments});
        restoredRefiners.push({refinerName: 'App owner', actions:selectedView.appOwnerAssignments});
        restoredRefiners.push({refinerName: 'Security', actions:selectedView.securityAssignments});
        restoredRefiners.push({refinerName: 'Compliance', actions:selectedView.complianceAssignments});

        setViewRefiners(restoredRefiners);

        setViewServices(selectedView.services);
    };

    const onTabSelect = async(event, data: OptionOnSelectData): Promise<void> => {
        setTabSelected(data.optionValue);

        setPageNumber(1);
    };

  const loadMessages = async(query: string, itAdminLevels: string[], appOwnerLevels: string[], complianceLevels: string[], securityLevels: string[], services: string[], refreshTenantData: boolean): Promise<MessageItem[]> => {
    let loadedMessages: MessageItem[] = [];

    if(props.license.Type === LicenseType.FreeTrial){
        loadedMessages = await MessageService.retrieveMessagesFreeTrial(query, itAdminLevels, appOwnerLevels, complianceLevels, securityLevels, services);
    }else{
        loadedMessages = await MessageService.retrieveMessages(query, props.license.Type !== LicenseType.Compliance, itAdminLevels, appOwnerLevels, complianceLevels, securityLevels, services, cutoffMonths, props.subscriptionKey);
    }

    const partitionKeyValue = setPartitionKeyValue();

    let tenantMessages: TenantMessageItem[] = [];
    
    if(refreshTenantData && props.license.Type !== LicenseType.FreeTrial){
        tenantMessages = await MessageService.retrieveTenantMessageItems(partitionKeyValue, props.subscriptionKey);

        setTenantMessageItems(tenantMessages);
    }else{
        tenantMessages = tenantMessageItems;
    }

    if(loadedMessages && loadedMessages.length > 0){
        if(tenantMessages && tenantMessages.length > 0){
            if(loadedMessages.length < tenantMessages.length){
                for (let i = 0; i < loadedMessages.length; i++){
                    const loadedMessage = loadedMessages[i];
    
                    const messageSpecific = tenantMessages.find((messageObj) => messageObj.messageId === loadedMessage.messageId);

                    const category = loadedMessage.messageCategory?.toLowerCase();

                    if(messageSpecific && (category !== 'content update' || (category === 'content update' && moment(messageSpecific.timeStamp).isAfter(moment(loadedMessage.messageDatePublishedRaw))))){
                        loadedMessage.isRead = messageSpecific.isRead;
                        loadedMessage.messageStatus = messageSpecific.messageStatus;
                    }
                }
            }else{
                for (let i = 0; i < tenantMessages.length; i++){
                    const tenantMessage = tenantMessages[i];
    
                    const messageSpecific = loadedMessages.find((messageObj) => messageObj.messageId === tenantMessage.messageId);

                    const category = messageSpecific?.messageCategory?.toLowerCase();

                    if(messageSpecific && (category !== 'content update' || (category === 'content update' && moment(tenantMessage.timeStamp).isAfter(moment(messageSpecific.messageDatePublishedRaw))))){
                        messageSpecific.isRead = tenantMessage.isRead;
                        messageSpecific.messageStatus = tenantMessage.messageStatus;
                    }
                }
            }                
        }            

        setMessages(loadedMessages);
    }        

    return loadedMessages;
  };

  const loadServices = async(): Promise<void> => {
    let services: string[] = [];

    if(props.license.Type === LicenseType.FreeTrial){
        services = await MessageService.retrieveServiceFacetsFreeTrial();
    }else{
        services = await MessageService.retrieveServiceFacets(props.subscriptionKey);
    }

    setViewServices([]);

    setLoadedServices(services);
  };

  const openMessage = async(messageItem: number | MessageItem): Promise<void> => {
    let message: MessageItem = undefined;

    if(typeof messageItem === 'number'){
        message = filteredMessages[messageItem];

        if(!message){
            message = messages[messageItem];
        }
    }else{
        message = messageItem as MessageItem;
    }

    if(message){   
        if(!message.isRead){
            message.isRead = !message.isRead;

            if(props.license.Type !== LicenseType.FreeTrial){
                await MessageService.upsertMessageItem(partitionKey, message.messageId, message.messageStatus, moment().toDate(), message.isRead, moment(message.messageDateCreated, 'DD.MM.YYYY').toDate(), props.subscriptionKey);
            }
            
            updateTenantItemState(message.messageId, message.isRead, message.messageStatus);
        }

        const comments = await CommentsService.getCommentsForMessage(message.messageId, rtcContext.siteUrl, rtcContext.sitePath, rtcContext.siteId);

        //eslint-disable-next-line require-atomic-updates
        message.messageComments = comments;

        setSelectedMessage(message);

        setOpenMessagePanel(true);
    }
  };
  
  const search = async(refreshTenantData: boolean): Promise<void> => {
      setIsLoading(true);

      setPageNumber(1);

      let itAdminFilter = [];
      let appOwnerFilter = [];
      let complianceFilter = [];
      let securityFilter = [];

      if(viewRefiners && viewRefiners.length > 0){
          itAdminFilter = viewRefiners.find((refiner) => refiner.refinerName === 'IT Administrator').actions.filter((action) => action.checked).map(action => action.action);
  
          appOwnerFilter = viewRefiners.find((refiner) => refiner.refinerName === 'App owner').actions.filter((action) => action.checked).map(action => action.action);
  
          complianceFilter = viewRefiners.find((refiner) => refiner.refinerName === 'Compliance').actions.filter((action) => action.checked).map(action => action.action);
  
          securityFilter = viewRefiners.find((refiner) => refiner.refinerName === 'Security').actions.filter((action) => action.checked).map(action => action.action);
      }

      //If all the options are selected then we remove the filter
      itAdminFilter = itAdminFilter.length === 4 ? [] : itAdminFilter as string[];
      appOwnerFilter = appOwnerFilter.length === 4 ? [] : appOwnerFilter as string[];
      complianceFilter = complianceFilter.length === 4 ? [] : complianceFilter as string[];
      securityFilter = securityFilter.length === 4 ? [] : securityFilter as string[];
      
      //If all the services are selected then we remove the filter
      const servicesFilter = viewServices.length === loadedServices.length ? [] : viewServices;

      const messagesLoaded = await loadMessages(searchQuery, itAdminFilter, appOwnerFilter, complianceFilter, securityFilter, servicesFilter, refreshTenantData);
      
      if(messagesLoaded && messagesLoaded.length > 0){
          const statusValue = tabSelected.toLocaleLowerCase();

          const preselectedMessages = messagesLoaded.filter((message) => message.messageStatus.toLocaleLowerCase() === statusValue);

          setFilteredMessages(preselectedMessages);
      }else{
          setFilteredMessages([]); 
      }

      setIsLoading(false);
  };

  const componentDidMount = async ():Promise<void> =>{
    const partitionKeyValue = setPartitionKeyValue();

    if((props.subscriptionKey && props.subscriptionKey.length > 0) || props.license.Type === LicenseType.FreeTrial){           
        setIsLoading(true);

        //Initial search is with all the filters blank except for the cut off date
        const loadedMessages = await loadMessages('', [], [], [], [], [], true);

        const inboxMessages = loadedMessages.filter(message => message.messageStatus.toLocaleLowerCase() === 'inbox');

        await loadServices();

        loadViewRefiners(false);         
        
        setFilteredMessages(inboxMessages);

        const messageId: string = rtcContext.teamsContext.page.subPageId;
        
        if(messageId && messageId.length > 0){           
            let message: MessageItem = undefined;

            if(loadedMessages && loadedMessages.length > 0){
                message = loadedMessages.find((msg) => msg.messageId === messageId);
            }             

            if(message){
                await openMessage(message);
            }else{
                const messageItem = await MessageService.retrieveMessage(messageId, props.subscriptionKey);

                if(messageItem){
                    const tenantMessageItem = await MessageService.retrieveTenantMessageItem(partitionKeyValue, messageId, props.subscriptionKey);

                    if(tenantMessageItem){
                        messageItem.isRead = tenantMessageItem.isRead;
                        messageItem.messageStatus = tenantMessageItem.messageStatus;
                    }

                    await openMessage(messageItem);
                }else{
                    dispatchToast(
                        <Toast>
                          <ToastTitle>{rtcContext.localization.WebParts.OperationMessages.openMessageError}</ToastTitle>
                        </Toast>,
                        { intent: 'error' }
                    );
                }
            }    
        }
    }

    setFirstLoad(false);

    setIsLoading(false);
  };

  useEffect(() => {
        const statusValue = tabSelected.toString().toLocaleLowerCase();
        
        const preselectedMessages = messages.filter((message) => message.messageStatus.toLocaleLowerCase() === statusValue);

        setFilteredMessages(preselectedMessages);
    }, [tabSelected]);

    useEffect(() => {
        if(!firstLoad){
            search(false);
        }        
    }, [viewServices, viewRefiners]);

    useEffect(() => {
        if(!firstLoad){
            search(true);
        }        
    }, [cutoffMonths]);
  
  useEffect(() => {
      //eslint-disable-next-line @typescript-eslint/no-floating-promises
      componentDidMount();
  }, []);

  useEffect(() => {
        loadViews();
    }, [loadedServices]);    
  
    useEffect(() => {
        if(firstLoad && viewItems && viewItems.length > 0){
            setSelectedView(viewItems[0]);
        }
    }, [viewItems]);
  
    useEffect(() => {
        if(!firstLoad){
            resetCustomRefiners();
        }        
    }, [selectedView]);

  const keyPressSearchMessages = (e):void => {
    //it triggers by pressing the enter key
    if (e.charCode === 13) {
        search(false); // eslint-disable-line @typescript-eslint/no-use-before-define, @typescript-eslint/no-floating-promises
    }
  };

  const renderViewOption = (viewItem: ViewItem): JSX.Element => {
    return (<MenuItem icon={viewItem.viewId === -1 ? <List20Regular /> : <Wand20Regular />} onClick={() => {setSelectedView(viewItem)}}>{viewItem.viewName}</MenuItem>);
  };

  const getActionName = (action: ViewRoleAssignment): string => {
    let severity: string = 'Ignore';

    switch(action.action){
        case MessageRoleAction.note:
            severity = 'Note';
            break;
        case MessageRoleAction.consider:
            severity = 'Consider';
            break;
        case MessageRoleAction.act:
            severity = 'Act';
            break;
    }

    return severity;
  };

  const addComment = async(): Promise<void> => {
        let filteredMessagesClone = JSON.parse(JSON.stringify(filteredMessages));

        let found = true;

        let messageToUpdate = filteredMessagesClone.find((messageObj) => { return messageObj.messageId === selectedMessage.messageId });

        if(!messageToUpdate){
            found = false;

            filteredMessagesClone = JSON.parse(JSON.stringify(messages));

            messageToUpdate = filteredMessagesClone.find((messageObj) => { return messageObj.messageId === selectedMessage.messageId });
        }        

        if(messageToUpdate.messageComments === undefined || !found){
            messageToUpdate.messageComments = await CommentsService.getCommentsForMessage(messageToUpdate.messageId, rtcContext.siteUrl, rtcContext.sitePath, rtcContext.siteId);
        }

        messageToUpdate.messageComments.unshift({
            commentContent: newComment,
            commentCreatedBy: rtcContext.userDisplayName,
            commentDateCreated: moment().format("DD.MM.YYYY")
        });

        setSelectedMessage(messageToUpdate);

        found ? setFilteredMessages(filteredMessagesClone) : setMessages(filteredMessagesClone);
    
        await CommentsService.saveCommentsForMessage(selectedMessage.messageId, newComment, rtcContext.siteUrl, rtcContext.sitePath);
        
        setNewComment('');  
    }; 

  const onCutoffDateSelect: ComboboxProps["onOptionSelect"] = (e, data) => {        
        const months = Number.parseInt(data.optionValue);

        if(months !== cutoffMonths){
            setCutoffMonths(months);
        }        
    };

  const onViewServiceRefinerChange = (checked: boolean, serviceId: string): void => {
          const viewServicesClone: string[] = JSON.parse(JSON.stringify(viewServices));
  
          if(checked){
              viewServicesClone.push(serviceId);
          }else{
              const posService = viewServicesClone.findIndex((service) => { return service === serviceId });
              viewServicesClone.splice(posService, 1);
          }
  
          setViewServices(viewServicesClone);
      };
  
    const onViewRolesChange = (isViewEdit: boolean, refiner: ViewRefiners, action: ViewRoleAssignment, checked: boolean): void => {
        if(isViewEdit){
            const editableViewClone: ViewItem = JSON.parse(JSON.stringify(editableView));

            switch(refiner.refinerName){
                case 'IT Administrator':
                    {
                        const actionToEdit = editableViewClone.itAdministratorAssignments.find((roleAssigment) => { return roleAssigment.action === action.action });
                        actionToEdit.checked = checked;
                    }                        
                    break;
                case 'App owner':
                    {
                        const actionToEdit = editableViewClone.appOwnerAssignments.find((roleAssigment) => { return roleAssigment.action === action.action });
                        actionToEdit.checked = checked;
                    }  
                    break;
                case 'Security':
                    {
                        const actionToEdit = editableViewClone.securityAssignments.find((roleAssigment) => { return roleAssigment.action === action.action });
                        actionToEdit.checked = checked;
                    }  
                    break;
                case 'Compliance':
                    {
                        const actionToEdit = editableViewClone.complianceAssignments.find((roleAssigment) => { return roleAssigment.action === action.action });
                        actionToEdit.checked = checked;
                    }  
                    break;
            }

            setEditableView(editableViewClone);

            const appliedViewClone: ViewRefiners[] = JSON.parse(JSON.stringify(editableViewRefiners));

            const refinerToUpdate: ViewRefiners = appliedViewClone.find((refinerObj) => { return refinerObj.refinerName === refiner.refinerName });

            if(refinerToUpdate){
                const actionToUpdate: ViewRoleAssignment = refinerToUpdate.actions.find((actionObj) => { return actionObj.action === action.action });

                if(actionToUpdate){
                    actionToUpdate.checked = checked;
                }
            }

            setEditableViewRefiners(appliedViewClone);
        }else{
            const appliedViewClone: ViewRefiners[] = JSON.parse(JSON.stringify(viewRefiners));

            const refinerToUpdate: ViewRefiners = appliedViewClone.find((refinerObj) => { return refinerObj.refinerName === refiner.refinerName });

            if(refinerToUpdate){
                const actionToUpdate: ViewRoleAssignment = refinerToUpdate.actions.find((actionObj) => { return actionObj.action === action.action });

                if(actionToUpdate){
                    actionToUpdate.checked = checked;
                }
            }

            setViewRefiners(appliedViewClone);
        }
    };

  const renderRoleRefiners = (refiner: ViewRefiners, isViewEdit: boolean): JSX.Element => {
    let showActions: boolean = false;
    let actionToggle: (show: boolean) => void;

    /* const itemsChecked: number = refiner.actions.filter((action) => { return action.checked === true }).length;
    let refinerCheck: boolean | 'mixed' = true; */

    /* if(itemsChecked === 0){
        refinerCheck = false;
    }else if(itemsChecked > 0 && itemsChecked < 4){
        refinerCheck = 'mixed';
    } */

    switch(refiner.refinerName){
        case 'IT Administrator':
            showActions = renderITAdminActionsRefiner;
            actionToggle = setRenderITAdminActionsRefiner;
            break;
        case 'App owner':
            showActions = renderAppOwnerActionsRefiner;
            actionToggle = setRenderAppOwnerActionsRefiner;
            break;
        case 'Security':
            showActions = renderSecurityActionsRefiner;
            actionToggle = setRenderSecurityActionsRefiner;
            break;
        case 'Compliance':
            showActions = renderComplianceActionsRefiner;
            actionToggle = setRenderComplianceActionsRefiner;
            break;
    }
    
    return(<>
      <StackShim>
          <div style={{display: 'block', width: 200}}>
              <Label style={{top: 4, position: 'relative', float: 'left'}}>{refiner.refinerName}</Label>
              <Button
                  appearance='subtle'
                  icon={!showActions ? <ChevronDown20Regular /> : <ChevronUp20Regular />}
                  onClick={() => actionToggle(!showActions)}
              />
          </div>                
          {showActions && <div style={{display: 'grid', paddingLeft: 30}}>
              {refiner.actions.map((action) => {
                  return(<Checkbox key={`${refiner.refinerName}_${action.action}`} label={getActionName(action)} checked={action.checked} onChange={(ev, data) => onViewRolesChange(isViewEdit, refiner, action, data.checked as boolean)} />)
              })}
          </div>}
      </StackShim>            
    </>);
  };

  const renderComment = (comment: MessageCommentItem): JSX.Element => {
        return(
            <StackShim>
                <div style={{display: 'flex', alignItems: 'center', marginTop: 15}}>
                    <Comment20Regular style={{marginRight: 15}} />
                    <StackShim>
                        <Label>{comment.commentCreatedBy}</Label>
                        <Label>{`${comment.commentDateCreated} ${comment.commentTimeCreated}`}</Label>                        
                    </StackShim>
                </div>                
                <div style={{marginTop: 5}} dangerouslySetInnerHTML={{__html: sanitize(comment.commentContent).replace(/<a /gmi, `<a target="_blank" `)}} />            
                {/* <Label style={{marginTop: 8}}>{comment.commentContent}</Label> */}
            </StackShim>
        );
    };

  const renderActionDetail = (actionCategory: string, actionText: string, severity: string): JSX.Element => {
        return(
            <div style={{width: '98%', display: 'inline-flex', marginBottom: 10, padding: 10, border: '1px solid #F8F8F8', boxShadow: '0px 0.6px 1.8px rgba(0, 0, 0, 0.25)',}}>
                <div style={{display: 'grid', paddingRight: 20, paddingLeft: 10, alignSelf: 'center', height: 46, textAlign: 'center'}}>
                    <Label>{actionCategory}</Label>
                    {ReactHelper.renderBadge(severity, rtcContext.localization)}
                </div>
                <Label style={{paddingLeft: 10, borderLeft: '1px solid #F8F8F8', width: 400}}>{actionText}</Label>
            </div>);
    };

  const renderMessageDetails = (): JSX.Element => {
        let messageStatus = rtcContext.localization.WebParts.Dashboard.inboxStatus;

        if(selectedMessage.messageStatus === 'Review'){
            messageStatus = rtcContext.localization.WebParts.Dashboard.inReviewStatus;
        }else if(selectedMessage.messageStatus === 'Archived'){
            messageStatus = rtcContext.localization.WebParts.Dashboard.archivedStatus;
        }

        let messageTimeline = selectedMessage.messageTimeline;

        var div = document.createElement("div");
        div.innerHTML = messageTimeline;
        
        if(!div.textContent || !div.innerText){
            messageTimeline = '<b>---</b>';
        }

        return(
            <div style={{display: 'inline-grid'}}>
                <Label>{`${rtcContext.localization.WebParts.Dashboard.publishedOn} ${selectedMessage.messageDatePublished}`}</Label>
                <div style={{display: 'flex'}}>
                    <div style={{paddingRight: 25, width: '66%', maxWidth: '66%'}}>
                        <Divider style={{marginTop: 20, marginBottom: 16}}>{rtcContext.localization.WebParts.MessagePanel.summary}</Divider>                        
                        {(selectedMessage.messageDescription && selectedMessage.messageDescription.length > 0) && <div dangerouslySetInnerHTML={{__html: sanitize(selectedMessage.messageDescription.replace(/style="width:.*?"/gmi,'style="width: 99%"')).toString().replace(/<a /gmi, `<a target="_blank" style="color: ${rtcContext.themeV9.colorBrandForegroundLink};"`)}} />}
                        <Divider style={{marginTop: 20, marginBottom: 16}}>{rtcContext.localization.WebParts.MessagePanel.roleRecommendations}</Divider>
                        {renderActionDetail('IT Admin', selectedMessage.messageITAdminText, selectedMessage.messageITAdminAction)}
                        {renderActionDetail('App Owner', selectedMessage.messageAppOwnerText, selectedMessage.messageAppOwnerAction)}
                        {renderActionDetail('Security', selectedMessage.messageSecurityText, selectedMessage.messageSecurityAction)}
                        {(props.license.Type === LicenseType.Compliance || props.license.Type === LicenseType.FreeTrial) && renderActionDetail('Compliance', selectedMessage.messageComplianceText, selectedMessage.messageComplianceAction)}
                        <Divider style={{marginTop: 20, marginBottom: 16}}>{rtcContext.localization.CommonStrings.Details}</Divider>
                        <Label style={{marginTop: 6}}>{rtcContext.localization.WebParts.Dialogs.status}</Label>
                        <br />
                        <Label style={{fontWeight: 600, display: 'block'}}>{messageStatus}</Label>
                        <div style={{display: 'inline-grid', marginBottom: 15, marginTop: 10}}>
                            <Label>{rtcContext.localization.WebParts.MessagePanel.timeline}</Label>
                            <div dangerouslySetInnerHTML={{__html: sanitize(messageTimeline)}} />
                        </div>
                        <StackShim>
                            <Label>{rtcContext.localization.WebParts.Dashboard.servicesGroup}</Label>
                            {selectedMessage.messageServices.map((service, index) => {
                                return (<Label key={service} style={{marginTop: index === 0 ? 0 : 5, fontWeight: 600}}>{service}</Label>);
                            })}
                            {(selectedMessage.messageRoadmapId && selectedMessage.messageRoadmapId.length > 0) &&
                                <>
                                    <Label style={{marginTop: 20}}>{rtcContext.localization.WebParts.MessagePanel.roadmapId}</Label>
                                    <Label style={{marginTop: 5, fontWeight: 600}}>{selectedMessage.messageRoadmapId}</Label>
                                </>
                            }
                        </StackShim> 
                        {props.license.Type !== LicenseType.Basic && <><Divider style={{marginTop: 20, marginBottom: 16}}>{selectedMessage.messageComments && selectedMessage.messageComments.length > 0 ? `${selectedMessage.messageComments.length} ` : ''}{rtcContext.localization.WebParts.MessagePanel.comments}</Divider>                
                        <div style={{display: 'flex', alignItems: 'center', marginBottom: 5}}>
                            <Input type='text' placeholder='Add a comment' style={{width: '100%', marginRight: 10}} value={newComment} onChange={(ev, data) => setNewComment(data.value)} />
                            <Button appearance='subtle' disabled={!newComment || newComment.length === 0} icon={<Send20Regular />} onClick={() => addComment()} />
                        </div> 
                        {(selectedMessage.messageComments && selectedMessage.messageComments.length > 0) && 
                            <>
                                {selectedMessage.messageComments.map((comment) => {
                                    return(renderComment(comment));
                                })}
                            </>
                        }</>}
                        <Divider style={{marginTop: 20, marginBottom: 16}}>{rtcContext.localization.WebParts.MessagePanel.originalMSMessage}</Divider>
                        {(selectedMessage.messageOriginalMSMessage && selectedMessage.messageOriginalMSMessage.length > 0) && <div dangerouslySetInnerHTML={{__html: sanitize(`<b>${selectedMessage.messageTitle}</b><br/><br/>${selectedMessage.messageOriginalMSMessage.replace(/style="width:.*?"/gmi,'style="width: 99%"')}`).toString().replace(/<a /gmi, `<a target="_blank" style="color: ${rtcContext.themeV9.colorBrandForegroundLink};"`)}} />}
                        
                    </div>
                </div>
            </div>
        );
    };

  return (
    <FluentProvider theme={rtcContext.themeV9} style={{width: '99%'}}>
        <Dialog onOpenChange={(_, { open }) => setOpenMessagePanel(open)} open={openMessagePanel} modalType="non-modal">                        
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>{selectedMessage && `${selectedMessage.messageId} | ${selectedMessage.messageRTCTitle}`}</DialogTitle>
                        <DialogContent>
                            {!selectedMessage && <Spinner />}
                            {selectedMessage && renderMessageDetails()}                                                          
                        </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>
      {(props.license.Type !== LicenseType.FreeTrial && (!props.subscriptionKey || props.subscriptionKey.length === 0)) && 
        <div style={{display: 'grid', justifyItems: 'center', top: '45%', left: '40%', position: 'absolute'}}>
            <Label style={{display: 'flex', fontSize: 18, fontWeight: 700}}>{rtcContext.localization.PropertyPaneStrings.welcomeTitle}</Label>
            <Label style={{display: 'flex', width: 365, textAlign: 'center', marginTop: 40, marginBottom: 30}}>{rtcContext.localization.PropertyPaneStrings.welcomeBody}</Label>
        </div>}
        {(props.license.Type === LicenseType.FreeTrial || (props.subscriptionKey && props.subscriptionKey.length > 0)) && 
          <div style={{padding: 10, position: isLoading ? 'fixed' : 'static', width: '100%'}}>
              {props.license.Type === LicenseType.FreeTrial && <MessageBar intent="info" style={{marginBottom: 20}}><MessageBarBody>{rtcContext.localization.WebParts.Dashboard.freeTrialDisclaimerP1}<Link href={`mailto:${AppConstants.runthecloudemail}?subject=${rtcContext.localization.PropertyPaneStrings.licenseEmailSubject}`} inline>{AppConstants.runthecloudemail}</Link>{rtcContext.localization.WebParts.Dashboard.freeTrialDisclaimerP2}</MessageBarBody></MessageBar>}                     
                  <div style={{width: '100%'}}>                            
                      <StackShim>
                            <Input type='text' required contentBefore={<Search20Regular />} placeholder={rtcContext.localization.CommonStrings.Search} value={searchQuery} onKeyPress={(ev) => keyPressSearchMessages(ev)} onChange={(ev, data) => {setSearchQuery(data.value)}} />
                            <div style={{marginTop: 20}}>
                                <Dropdown style={{float: 'left', width: '35%', minWidth: 0, top: -8}} defaultSelectedOptions={['inbox']} defaultValue={rtcContext.localization.WebParts.Dashboard.inboxStatus} onOptionSelect={onTabSelect}>
                                    <Option value='inbox' text={rtcContext.localization.WebParts.Dashboard.inboxStatus}>{rtcContext.localization.WebParts.Dashboard.inboxStatus}</Option>
                                    <Option value='review' text={rtcContext.localization.WebParts.Dashboard.inReviewStatus}>{rtcContext.localization.WebParts.Dashboard.inReviewStatus}</Option>
                                    <Option value='archived' text={rtcContext.localization.WebParts.Dashboard.archivedStatus}>{rtcContext.localization.WebParts.Dashboard.archivedStatus}</Option>
                                </Dropdown>
                                <Tooltip withArrow content={rtcContext.localization.WebParts.Tooltips.support} relationship="label">
                                    <PersonSupport20Regular style={{marginLeft: 10, float: 'right'}} onClick={() => {window.open(`mailto:${AppConstants.runthecloudemail}?subject=[${rtcContext.upn.split('@')[1].split('.')[0].toUpperCase()}] - ${rtcContext.localization.WebParts.Dashboard.supportMailSubject}&body=${rtcContext.localization.WebParts.Dashboard.supportMailDescription}`)}} />
                                </Tooltip>
                                <Tooltip withArrow content={rtcContext.localization.WebParts.Tooltips.openPanel} relationship="label"><Filter20Regular style={{width: 40, float: 'right', paddingLeft: 10,}} onClick={() => setOpenFilters(true)} /></Tooltip>
                                {(selectedView && viewItems && viewItems.length > 0) &&                             
                                    <Menu>
                                        <MenuTrigger disableButtonEnhancement>
                                            <MenuButton appearance="subtle" style={{height: 20, float: 'right'}} icon={selectedView.viewId === -1 ? <List20Regular /> : <Wand20Regular />}>{selectedView.viewName}</MenuButton>
                                        </MenuTrigger>
                                        <MenuPopover>
                                            <MenuList>
                                                {viewItems.map((viewItem) => { return (renderViewOption(viewItem));})}                                                
                                            </MenuList>
                                        </MenuPopover>
                                    </Menu>}                          
                            </div>
                      </StackShim>
                      {(props.subscriptionKey && props.subscriptionKey.length > 0 && isLoading) && <Spinner style={{paddingTop: 20, width: '100%'}} />}
                      {(((props.subscriptionKey && props.subscriptionKey.length > 0) || props.license.Type === LicenseType.FreeTrial) && !isLoading) && 
                      <div style={{display: 'flex'}}>
                          <div>
                              {(filteredMessages && filteredMessages.length > 0) && 
                                  <div style={{display: 'inline-block'}}>
                                      {filteredMessages.slice(30*(pageNumber-1), 30*(pageNumber-1)+29).map((message, index) => {
                                          return (<MessageCardMobile key={message.messageId} rtcContext={rtcContext} index={index} message={message} license={props.license} themeV9={rtcContext.themeV9} isIos={props.isIos} openMessage={openMessage} />);
                                      })}
                                      <div className={`${paginationClassName.pagination} ${paginationClassName.paginationColor}`} style={{color: rtcContext.themeV9.colorBrandForegroundLink}}>
                                          <Pagination
                                              activePage={pageNumber}
                                              firstPageText={<Icon iconName="DoubleChevronLeft"/>}
                                              lastPageText={<Icon iconName="DoubleChevronRight"/>}
                                              prevPageText={<Icon iconName="ChevronLeft"/>}
                                              nextPageText={<Icon iconName="ChevronRight"/>}
                                              activeLinkClass={"active"}
                                              itemsCountPerPage={30}
                                              totalItemsCount={filteredMessages.length}
                                              pageRangeDisplayed={5}
                                              onChange={setPageNumber}
                                              />
                                      </div>
                                      <Label style={{display: 'flex', marginBottom: 50, justifyContent: 'center'}}>{rtcContext.localization.WebParts.Dashboard.pagingResults.replace('{0}', filteredMessages.length.toString())}</Label>
                                  </div>                        
                              }
                              {(!filteredMessages || filteredMessages.length === 0) && <Label style={{display: 'flex', marginTop: 50, justifyContent: 'center', textAlign: 'center', width: '100%', position: 'fixed'}}>{rtcContext.localization.WebParts.Dashboard.noMessagesToShow}</Label>}
                          </div>                                
                          <Dialog onOpenChange={(_, { open }) => setOpenFilters(open)} open={openFilters} modalType="non-modal">                        
                            <DialogSurface>
                                <DialogBody>
                                    <DialogTitle>{rtcContext.localization.CommonStrings.Filters}</DialogTitle>
                                        <DialogContent>
                                            <StackShim>
                                                <Label style={{fontWeight: 600, marginTop: 10}}>{rtcContext.localization.CommonStrings.Period}</Label>
                                                <Tooltip withArrow content={rtcContext.localization.WebParts.Tooltips.timeframe} relationship="label">
                                                    <Combobox defaultSelectedOptions={['3']} defaultValue={rtcContext.localization.WebParts.Dashboard.last3Months} onOptionSelect={onCutoffDateSelect} style={{width: 200, marginTop: 5}}>
                                                        <Option value='3' text={rtcContext.localization.WebParts.Dashboard.last3Months}>{rtcContext.localization.WebParts.Dashboard.last3Months}</Option>
                                                        <Option value='6' text={rtcContext.localization.WebParts.Dashboard.last6Months}>{rtcContext.localization.WebParts.Dashboard.last6Months}</Option>
                                                        <Option value='12' text={rtcContext.localization.WebParts.Dashboard.last12Months}>{rtcContext.localization.WebParts.Dashboard.last12Months}</Option>
                                                        <Option value='18' text={rtcContext.localization.WebParts.Dashboard.last18Months}>{rtcContext.localization.WebParts.Dashboard.last18Months}</Option>
                                                        <Option value='24' text={rtcContext.localization.WebParts.Dashboard.last24Months}>{rtcContext.localization.WebParts.Dashboard.last24Months}</Option>
                                                        <Option value='30' text={rtcContext.localization.WebParts.Dashboard.last30Months}>{rtcContext.localization.WebParts.Dashboard.last30Months}</Option>
                                                    </Combobox>
                                                </Tooltip>
                                            </StackShim>
                                            <Divider style={{marginTop: 15, marginBottom: 5}} />                                      
                                            <Label style={{fontWeight: 600, marginTop: 10}}>{rtcContext.localization.WebParts.Dashboard.rolesGroup}</Label>
                                            <br />
                                            {(viewRefiners && viewRefiners.length > 0) &&
                                                viewRefiners.map((refiner) => {
                                                    if(props.license.Type === LicenseType.Compliance || props.license.Type === LicenseType.FreeTrial || refiner.refinerName !== 'Compliance'){
                                                        return(renderRoleRefiners(refiner, false));
                                                    }
                                                })
                                            }
                                            <Divider style={{marginTop: 15, marginBottom: 5}} />
                                            <Label style={{fontWeight: 600, marginBottom: 15}}>{rtcContext.localization.WebParts.Dashboard.servicesGroup}</Label>
                                            {loadedServices.map((service) => {
                                                return (<><br /><Checkbox key={`chk_${service}`} label={service} checked={viewServices.find((viewService) => { return viewService === service}) !== undefined} onChange={(ev, data) => onViewServiceRefinerChange(data.checked as boolean, service)} /></>);
                                            })}                                                           
                                        </DialogContent>
                                </DialogBody>
                            </DialogSurface>
                          </Dialog>
                      </div>}                                                      
                  </div>                         
              </div>}      
      <Toaster toasterId={toasterId} />   
    </FluentProvider>
  );
}