import { MessageCommentItem } from "../../interfaces/IMessageItem";
import moment from "moment";
import axios, { AxiosHeaders } from "axios";
import { GetGraphAccessToken, GetSharePointAccessToken } from "../HttpClientService/HttpClientService";

export default class CommentsService {
    private static async initializeCommentsListGraph(siteId: string):Promise<void> { 
        const requestCreateViewsListGraph = {
            "displayName": "RtCComments",
            "columns": [
              {
                "name": "Comment",
                "text": {
                    "allowMultipleLines": true,
                    "appendChangesToExistingText": false,
                    "linesForEditing": 6,
                    "maxLength": 300,
                    "textType": "plain"
                 }
              }
            ],
            "list": {
              "template": "genericList"
            }
        };

        const accessToken = await GetGraphAccessToken();

        const requestHeaders: AxiosHeaders = new AxiosHeaders();
        
        requestHeaders.set('Authorization', `Bearer ${accessToken}`);
        requestHeaders.set('Content-type', 'application/json');

        const respCreateViewsList = await axios.post(`https://graph.microsoft.com/v1.0/sites/${siteId}/lists`, requestCreateViewsListGraph, { headers: requestHeaders });

        console.log('respCreateViewsList', respCreateViewsList);
    }

    public static async getCommentsForMessage(messageId: string, siteUrl: string, teamSitePath: string, siteId: string): Promise<MessageCommentItem[]> {
        const results: Array<MessageCommentItem> = new Array<MessageCommentItem>();

        const listUrl = `${teamSitePath}/Lists/RtCComments`;

        const restUrl = `${siteUrl}/_api/web/GetList(@listUrl)/RenderListDataAsStream?@listUrl=%27${encodeURIComponent(listUrl)}%27`;

        const requestBody = {
            parameters: {
                RenderOptions: 2,
                DatesInUtc: true,
                ViewXml: 
                `<View>
                    <Query>
                        <Where>
                            <Eq>
                                <FieldRef Name="Title" />
                                <Value Type="Text">${messageId.toUpperCase()}</Value>
                            </Eq>
                        </Where>
                        <OrderBy><FieldRef Name="Created" Ascending="False" /></OrderBy>
                    </Query>
                    <ViewFields>
                        <FieldRef Name="ID"/>
                        <FieldRef Name="Title"/>
                        <FieldRef Name="Comment"/>
                        <FieldRef Name="Created"/>
                        <FieldRef Name="Author"/>
                    </ViewFields>
                    <RowLimit>5000</RowLimit>
                </View>`
            }
        };

        const accessToken = await GetSharePointAccessToken();

        const requestHeaders: AxiosHeaders = new AxiosHeaders();
        
        requestHeaders.set('Authorization', `Bearer ${accessToken}`);
        requestHeaders.set('odata-version', '3.0');

        try{
            const response = await axios.post(restUrl, requestBody, { headers: requestHeaders });

            if(response.status >= 200 && response.status <= 300){
                const responseJSON = response.data;
    
                if(responseJSON && responseJSON.Row.length){
                    for(const item of responseJSON.Row){
                        const commentItem: MessageCommentItem = {
                            commentDateCreated: moment(item.Created).format("DD.MM.YYYY"),
                            commentTimeCreated: moment(item.Created).format("HH.mm"),
                            commentCreatedBy: item.Author[0].title,
                            commentContent: item.Comment
                        };
                
                        results.push(commentItem);
                    }
                }            
            }else{
                await this.initializeCommentsListGraph(siteId);
            }
        }catch{
            await this.initializeCommentsListGraph(siteId);
        }        

        return results;
    }

    public static async saveCommentsForMessage(messageId: string, messageContent: string, siteUrl: string, teamSitePath: string): Promise<void> {
        try{
            const listUrl = `${teamSitePath}/Lists/RtCComments`;

            const restUrl: string = `${siteUrl}/_api/web/GetList(@listUrl)/items?@listUrl=%27${encodeURIComponent(listUrl)}%27`;
    
            const requestBody = {
                __metadata: {
                   type:`SP.Data.RtCCommentsListItem`
                },
                Title: messageId.toUpperCase(),
                Comment: messageContent
            };
      
            const accessToken = await GetSharePointAccessToken();

            const requestHeaders: AxiosHeaders = new AxiosHeaders();
            
            requestHeaders.set('Authorization', `Bearer ${accessToken}`);
            requestHeaders.set('odata-version', '3.0');
            requestHeaders.set('Accept', 'application/json;odata=verbose');
            requestHeaders.set('Content-type', 'application/json;odata=verbose');

            const response = await axios.post(restUrl, requestBody, { headers: requestHeaders });

            if(response.status >= 400){
                const errorMessage = await response.data;

                console.error(errorMessage);
            }
        }catch(ex){
            console.error(ex);
        }        
    }
}