import * as React from 'react';
import { LicenseType } from '../../common/interfaces/ILicenseStatus';
import { StackShim } from '@fluentui/react-migration-v8-v9';
import { Label, makeStyles, shorthands } from '@fluentui/react-components';
import { ReactHelper } from '../../common/helpers/ReactHelper';
import { IMobileMessageCardProps } from '../../common/interfaces/IMessageCardProps';

const messageStyles = makeStyles({
    badgeLabelStyle: {
        ...shorthands.padding('12px, 10px, 12px, 10px'), 
        color: '#BDBDBD', 
        display: 'block', 
        textAlign: 'center', 
        width: '75px', 
        marginBottom: '5px'
    },
    mainMessageBody: {             
        display: 'inline-flex',
        width: '98.5%',
        ...shorthands.padding('10px'),
        boxShadow: '0px 0.6px 1.8px rgba(0, 0, 0, 0.25)',
        ...shorthands.borderTop('1px solid #F8F8F8'),
        ...shorthands.borderRight('1px solid #F8F8F8'),
        ...shorthands.borderBottom('1px solid #F8F8F8')        
    },
    drawerTitle: {
        '> h2': {
            width: '85% !important'
        }      
    },
    whiteGlove: {
        ':hover': {
            cursor: 'pointer'
        }
    }
});

export const MessageCardMobile: React.FunctionComponent<IMobileMessageCardProps> = (props) => {
    const styles = messageStyles();

    const titleParts = `${props.message.messageId} | ${props.message.messageRTCTitle}`;

    const cardTitle = titleParts.length > 40 ? `${titleParts.substring(0, 40)}...` : titleParts;

    const labelParts = `${props.rtcContext.localization.WebParts.Dashboard.publishedOn} ${props.message.messageDatePublished} | ${(props.message.messageServices && props.message.messageServices.length > 0) ? ReactHelper.renderServicesString(props.message) : ''}`;

    const cardLabel = labelParts.length > 65 ? `${labelParts.substring(0, 65)}...` : labelParts;

    return(
        <div style={{display: 'flex', position: 'relative', left: props.message.isRead ? 0 : -5, marginTop: 10}}>
            {!props.message.isRead && <div style={{backgroundColor: props.rtcContext.themeV9.colorCompoundBrandStroke, width: 5}} />}
            <div key={props.message.messageId} className={styles.mainMessageBody}>                    
                <div>
                    <div style={{display: 'grid'}}>
                        <StackShim>
                            <div>
                                <Label style={{fontSize: 16, fontWeight: 600}} className={styles.whiteGlove} onClick={() => props.openMessage(props.message)}>{cardTitle}</Label>
                                <div style={{alignItems: 'baseline', marginTop: 10}}><Label style={{fontSize: 12}}>{cardLabel}</Label></div>                                
                            </div>
                            <div style={{display: 'inline-flex', marginTop: 10}}>
                                <div style={{marginRight: 5}}>
                                    {ReactHelper.renderBadgeMobile(props.message.messageITAdminAction, props.rtcContext.localization.WebParts.Dashboard.itAdmin)}
                                </div>
                                <div style={{marginRight: props.isIos ? 15 : 5}}>
                                    {ReactHelper.renderBadgeMobile(props.message.messageAppOwnerAction, props.rtcContext.localization.WebParts.Dashboard.appOwner)}
                                </div>
                                <div style={{marginRight: 5}}>
                                    {ReactHelper.renderBadgeMobile(props.message.messageSecurityAction, props.rtcContext.localization.WebParts.Dashboard.security)}
                                </div>
                                {(props.license.Type === LicenseType.Compliance || props.license.Type === LicenseType.FreeTrial) && <div>
                                    {ReactHelper.renderBadgeMobile(props.message.messageComplianceAction, props.rtcContext.localization.WebParts.Dashboard.compliance)}
                                </div>}
                            </div>
                        </StackShim>                          
                    </div> 
                </div>
            </div>
        </div>
    );
}