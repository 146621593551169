export default class LocalizationService {
    public static async loadLocale(locale: string) {
        try{
            if(!locale || locale.length === 0){ 
                return await import('./en.json');
            }
    
            const localeParts = locale.split('-');
    
            const language = localeParts[0];
    
            const localeModule = await import(`./${language}.json`);
    
            return localeModule;
        }catch{
            return await import('./en.json');
        }        
    }
}